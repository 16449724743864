const testIds = {
    reviewApplication: {
        availability: "availability"
    },
    searchForm: {
        search: "search-btn",
        showMobileForm: "search-show-mobile-form"
    },
    searchResults: {
        communityName: "results-community-name-",
        contactEmail: "results-contact-email-",
        contactName: "results-contact-name-",
        game: "results-game-",
        licenseNumber: "results-license-number-",
        name: "results-name-",
        desktop: "results-desktop",
        mobile: "results-mobile",
        mobileValue: "results-mobile-value-",
        sortMobile: "sort-mobile",
        status: "results-status-"
    }
} as const;

export default testIds;
