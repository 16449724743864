import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import localAuthToken from "../../shared/localStorage/localAuthToken";
import { api, apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";
import { AuthToken } from "../../shared/utils/jwtUtils";

export const namespace = "login";

export interface LoginValues {
    username: string;
    password: string;
}

export interface Login {
    loading: string;
    isLoggedIn: boolean;
    promptLogin: boolean;
    error: AppError | null;
}

const initialState: Login = {
    loading: httpStatus.idle,
    isLoggedIn: !!localAuthToken.get(),
    promptLogin: false,
    error: null
};

export const loginUser = createAsyncThunk<
    AuthToken,
    LoginValues,
    { rejectValue: AppError }
>(
    `${namespace}/loginUser`,
    async (values: LoginValues, { rejectWithValue }) => {
        try {
            const authToken = await apiNoAuth.post<LoginValues, AuthToken>(
                endpoints.user.login,
                {
                    username: values.username,
                    password: values.password
                }
            );
            return authToken;
        } catch (error) {
            return rejectWithValue(error as AppError);
        }
    }
);

export const logoutUser = createAsyncThunk(
    `${namespace}/logoutUser`,
    async () => {
        try {
            return await api.post<string, null>(endpoints.user.logout, "");
        } finally {
            localAuthToken.clear();
        }
    }
);

const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        resetStore() {
            // do nothing; see store for side effect
        },
        promptLogin(state, action: PayloadAction<boolean>) {
            state.promptLogin = action.payload;
        },
        localLogout(state) {
            state.isLoggedIn = false;
            localAuthToken.clear();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loading = httpStatus.pending;
        });
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            localAuthToken.set(payload);
            state.isLoggedIn = true;
            state.promptLogin = false;
            state.error = null;
        });
        builder.addCase(loginUser.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(logoutUser.pending, (state) => {
            state.loading = httpStatus.pending;
            state.isLoggedIn = false;
            state.error = null;
        });
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.loading = httpStatus.fulfilled;
            state.isLoggedIn = false;
            state.error = null;
        });
        builder.addCase(logoutUser.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.isLoggedIn = false;
            state.error = payload as AppError;
        });
    }
});

export const { resetStore, promptLogin, localLogout } = loginSlice.actions;

export default loginSlice.reducer;
