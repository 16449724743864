import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";

const namespace = "game";

export const newGameId = "newGame";

export interface UpdatedGame {
    name: string;
    enabled: boolean;
    licenseAgreementUrl: string;
}

export interface GameData {
    enabled: boolean;
    id: string;
    licenseAgreementUrl: string;
    name: string;
}

export interface Game {
    loading: string;
    list: GameData[];
    single: GameData | null;
    error: AppError | null;
}

const initialState: Game = {
    loading: httpStatus.idle,
    list: [],
    single: null,
    error: null
};

export const newGamePrep: GameData = {
    name: "",
    id: newGameId,
    enabled: false,
    licenseAgreementUrl: ""
};

export const getGame = createAsyncThunk<
    GameData,
    string,
    { rejectValue: AppError }
>(`${namespace}/getGame`, async (id, { rejectWithValue }) => {
    if (id === newGameId) {
        return newGamePrep;
    }
    try {
        return await apiNoAuth.get<GameData>(endpoints.game.get(id));
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export const getList = createAsyncThunk<
    GameData[],
    void,
    { rejectValue: AppError }
>(`${namespace}/getList`, async (_, { rejectWithValue }) => {
    try {
        return await apiNoAuth.get<GameData[]>(endpoints.game.get());
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export const updateGame = createAsyncThunk<
    GameData,
    GameData,
    { rejectValue: AppError }
>(`${namespace}/updateGame`, async (values: GameData, { rejectWithValue }) => {
    try {
        const { id, ...valuesNoId } = values;
        if (id === newGameId) {
            return await api.post<UpdatedGame, GameData>(
                endpoints.game.post,
                valuesNoId
            );
        }
        return await api.put<UpdatedGame, GameData>(
            endpoints.game.put(id),
            valuesNoId
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const sortGames = (list: GameData[]) =>
    list.sort((a, b) =>
        a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
    );

const gameSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGame.pending, (state) => {
            state.loading = httpStatus.pending;
            state.single = null;
            state.error = null;
        });
        builder.addCase(getGame.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.single = payload as GameData;
            state.error = null;
        });
        builder.addCase(getGame.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(getList.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.list = sortGames((payload as GameData[]) || []);
            state.error = null;
        });
        builder.addCase(getList.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(updateGame.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(updateGame.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
            state.single = payload as GameData;
            const index = state.list?.findIndex(
                (game) => payload.id === game.id
            );
            if (index !== undefined && state.list) {
                state.list[index] = payload;
            }
            state.list = sortGames(state.list);
        });
        builder.addCase(updateGame.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default gameSlice.reducer;
