import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";

const namespace = "buildVersion";

export interface BuildVersion {
    loading: string;
    data: string | null;
    error: AppError | null;
}

const initialState: BuildVersion = {
    loading: httpStatus.idle,
    data: null,
    error: null
};

export const getShortBuildVersion = (version: string | undefined | null) =>
    version ? version.split("-")[0].trim() : "";

export const getVersion = createAsyncThunk<
    string,
    void,
    { rejectValue: AppError }
>(`${namespace}/getVersion`, async (_, { rejectWithValue }) => {
    try {
        if (process.env.NODE_ENV === "development") {
            return process.env.NODE_ENV;
        }
        return await apiNoAuth.get<string>(endpoints.version.get);
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const buildVersionSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVersion.pending, (state) => {
            state.loading = httpStatus.pending;
            state.data = null;
            state.error = null;
        });
        builder.addCase(getVersion.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.data = payload;
            state.error = null;
        });
        builder.addCase(getVersion.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default buildVersionSlice.reducer;
