import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Col, Row, Card } from "react-bootstrap";
import KeyValue from "../../shared/components/KeyValue";
import { getLanguage } from "../../shared/utils/languageUtil";
import { GeneralData } from "./server.slice";

const GeneralInfoCard: React.FC<{
    data: GeneralData;
}> = ({ data }) => {
    const { t: translate } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Card.Title>{translate("generalForm.title")}</Card.Title>
                <Row>
                    <Col md={6}>
                        <KeyValue
                            keyName={translate("generalForm.serverName")}
                            value={data.name}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.game")}
                            value={data.gameName}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.serverProvider")}
                            value={data.serverProviderName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.ipAddress")}
                            value={data.ipAddress}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.availableAdmins")}
                            value={data.availableAdmins}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.playerSlots")}
                            value={data.playerSlots}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("generalForm.languagesList")}
                            value={data.locales
                                .map((item) => getLanguage(item))
                                .join(", ")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <KeyValue
                            keyName={translate(
                                "generalForm.additionalComments"
                            )}
                            value={data.additionalComments}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default GeneralInfoCard;
