import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Spinner } from "react-bootstrap";

const LoadingSpinner: React.FC<{ variant?: string }> = ({
    variant = "primary"
}) => {
    const { t: translate } = useTranslation();

    return (
        <Spinner animation="border" variant={variant} role="status" size="sm">
            <span className="visually-hidden">
                {translate("common.loading")}
            </span>
        </Spinner>
    );
};

export default LoadingSpinner;
