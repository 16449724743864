import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useDispatch } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { setHardware } from "./licenseApplication.slice";
import { AppDispatch } from "../../shared/store";
import DetectCompletedForm from "../../shared/components/DetectCompletedForm";
import {
    chevronLeftIcon,
    chevronRightIcon,
    requiredFieldIcon
} from "../../shared/constants/unicodeIcons";
import { FormStep } from "../../pages/ApplicationPage";
import { HardwareData } from "../servers/server.slice";
import { maxLengthMedium } from "../../shared/constants/validation";

const HardwareForm: React.FC<FormStep> = ({
    completedHandler,
    nextStepHandler,
    backStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const schema = Yup.object().shape({
        cpuFrequency: Yup.string().required(
            translate("hardwareForm.cpuFrequencyRequired")
        ),
        cpuModel: Yup.string().required(
            translate("hardwareForm.cpuModelRequired")
        ),
        diskDrives: Yup.string().required(
            translate("hardwareForm.diskDrivesRequired")
        ),
        location: Yup.string().required(
            translate("hardwareForm.locationRequired")
        ),
        networkSpeed: Yup.string().required(
            translate("hardwareForm.networkSpeedRequired")
        ),
        operatingSystem: Yup.string().required(
            translate("hardwareForm.operatingSystemRequired")
        ),
        physicalMemory: Yup.string().required(
            translate("hardwareForm.physicalMemoryRequired")
        )
    });

    const initialValues: HardwareData = {
        cpuFrequency: "",
        cpuModel: "",
        diskDrives: "",
        hostingCompany: "",
        location: "",
        networkSpeed: "",
        operatingSystem: "",
        physicalMemory: ""
    };

    const submitHandler = (values: HardwareData) => {
        dispatch(setHardware(values));
        if (nextStepHandler) {
            nextStepHandler();
        }
    };

    return (
        <div>
            <h5>{translate("hardwareForm.title")}</h5>
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={submitHandler}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <DetectCompletedForm
                            completedHandler={completedHandler}
                        />
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.operatingSystem"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.operatingSystem")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="operating-system-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.operatingSystemPlaceholder"
                                    )}
                                    name="operatingSystem"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.operatingSystem}
                                    isInvalid={
                                        !!touched.operatingSystem &&
                                        !!errors.operatingSystem
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.operatingSystem}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.cpuModel"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.cpuModel")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="cpu-model-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.cpuModelPlaceholder"
                                    )}
                                    name="cpuModel"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cpuModel}
                                    isInvalid={
                                        !!touched.cpuModel && !!errors.cpuModel
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.cpuModel}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.cpuFrequency"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.cpuFrequency")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="cpu-frequency-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.cpuFrequencyPlaceholder"
                                    )}
                                    name="cpuFrequency"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cpuFrequency}
                                    isInvalid={
                                        !!touched.cpuFrequency &&
                                        !!errors.cpuFrequency
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.cpuFrequency}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.physicalMemory"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.physicalMemory")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="physical-memory-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.physicalMemoryPlaceholder"
                                    )}
                                    name="physicalMemory"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.physicalMemory}
                                    isInvalid={
                                        !!touched.physicalMemory &&
                                        !!errors.physicalMemory
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.physicalMemory}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.diskDrives"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.diskDrives")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="disk-drives-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.diskDrivesPlaceholder"
                                    )}
                                    name="diskDrives"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.diskDrives}
                                    isInvalid={
                                        !!touched.diskDrives &&
                                        !!errors.diskDrives
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.diskDrives}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="LicenseHardwareForm.networkSpeed"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.networkSpeed")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="network-speed-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.networkSpeedPlaceholder"
                                    )}
                                    name="networkSpeed"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.networkSpeed}
                                    isInvalid={
                                        !!touched.networkSpeed &&
                                        !!errors.networkSpeed
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.networkSpeed}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md={8}
                                controlId="LicenseHardwareForm.location"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.location")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="location-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.locationPlaceholder"
                                    )}
                                    name="location"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.location}
                                    isInvalid={
                                        !!touched.location && !!errors.location
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.location}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md={4}
                                controlId="LicenseHardwareForm.hostingCompany"
                            >
                                <Form.Label>
                                    {translate("hardwareForm.hostingCompany")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="hosting-company-input"
                                    type="text"
                                    placeholder={translate(
                                        "hardwareForm.hostingCompanyPlaceholder"
                                    )}
                                    name="hostingCompany"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.hostingCompany}
                                    isInvalid={
                                        !!touched.hostingCompany &&
                                        !!errors.hostingCompany
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.hostingCompany}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="d-flex">
                            <Button
                                variant="secondary"
                                role="button"
                                type="button"
                                onClick={backStepHandler}
                            >
                                {chevronLeftIcon} {translate("common.back")}
                            </Button>
                            <Button
                                variant="primary"
                                className="ms-auto"
                                role="button"
                                type="submit"
                            >
                                {translate("common.next")} {chevronRightIcon}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default HardwareForm;
