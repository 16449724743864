import { AxiosResponse, AxiosError } from "axios";
import { localLogout, promptLogin } from "../../features/login/login.slice";
import AppError from "../errors/appError";
import localAuthToken from "../localStorage/localAuthToken";
import store from "../store";
import { ApiRequest, getTokenPrefix } from "./api";
import httpClient from "./httpClient";
import endpoints from "./endpoints";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ApiError<T = any, D = any> extends AxiosError<T, D> {
    config: ApiRequest;
}

export const interceptorRejectReason = {
    tokenRefresh: "Should not retry a failed token refresh",
    login: "Should not refresh token on a failed login",
    logout: "Should not refresh token on a failed logout",
    retryRetry: "Should not retry a failed retry",
    nonAuthError: "Should not refresh token on a non 401 error",
    noFreshToken: "No refresh token found",
    failedRefresh: "Unable to refresh token"
};

export const authTokenInterceptor = (config: ApiRequest): ApiRequest => {
    if (!config.isAuth) {
        return config;
    }
    const token = localAuthToken.get()?.access;
    if (token) {
        const newConfig = { ...config };
        if (newConfig.headers) {
            newConfig.headers.Authorization = getTokenPrefix(token);
        }
        return newConfig;
    }
    store.dispatch(localLogout());
    return config;
};

export const refreshAuthResponseInterceptor = async (
    originalError: ApiError<AppError>
): Promise<AxiosResponse<unknown, unknown>> => {
    const newError = {
        ...originalError,
        config: originalError.config
    };

    // Reject when retrying an already retried request
    if (newError.config.isRetry) {
        newError.config.interceptorRejectReason =
            interceptorRejectReason.retryRetry;
        return Promise.reject(newError);
    }

    // No need to refresh token on a non 401 error
    if (newError.response?.data.code !== 401) {
        newError.config.interceptorRejectReason =
            interceptorRejectReason.nonAuthError;
        return Promise.reject(newError);
    }

    // Reject refreshing the token on 401 errors for certain endpoints
    if (newError.config.url === endpoints.user.login) {
        newError.config.interceptorRejectReason = interceptorRejectReason.login;
        return Promise.reject(newError);
    }
    if (newError.config.url === endpoints.user.logout) {
        newError.config.interceptorRejectReason =
            interceptorRejectReason.logout;
        return Promise.reject(newError);
    }
    if (newError.config.url === endpoints.user.refreshToken) {
        newError.config.interceptorRejectReason =
            interceptorRejectReason.tokenRefresh;
        return Promise.reject(newError);
    }

    // Logout if there is no refresh token
    const refreshToken = localAuthToken.get()?.refresh;
    if (!refreshToken) {
        store.dispatch(localLogout());
        newError.config.interceptorRejectReason =
            interceptorRejectReason.noFreshToken;
        return Promise.reject(newError);
    }

    // Attempt to refresh to token, update it, and retry the original request
    try {
        const response = await httpClient.get(endpoints.user.refreshToken, {
            headers: {
                Authorization: getTokenPrefix(refreshToken)
            }
        });

        localAuthToken.set({
            access: response.data.access,
            refresh: refreshToken
        });

        if (newError?.response?.config.headers) {
            newError.response.config.headers.Authorization = getTokenPrefix(
                response.data.access
            );
        }
        newError.config.isRetry = true;

        return await httpClient.request(newError.config);
    } catch (error) {
        newError.config.interceptorRejectReason =
            interceptorRejectReason.failedRefresh;
        store.dispatch(promptLogin(true));
        return Promise.reject(newError);
    }
};

const setupInterceptors = (): Promise<void> => {
    httpClient.interceptors.request.use(authTokenInterceptor);
    httpClient.interceptors.response.use(
        (response) => response,
        refreshAuthResponseInterceptor
    );
    return Promise.resolve();
};

export default setupInterceptors;
