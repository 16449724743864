import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useDispatch } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import { Field, FieldArray, FieldMetaProps, Formik } from "formik";
import * as Yup from "yup";
import {
    setPrimaryContact,
    setAdditionalContacts
} from "./licenseApplication.slice";
import { AppDispatch } from "../../shared/store";
import DetectCompletedForm from "../../shared/components/DetectCompletedForm";
import {
    chevronLeftIcon,
    chevronRightIcon,
    requiredFieldIcon
} from "../../shared/constants/unicodeIcons";
import { FormStep } from "../../pages/ApplicationPage";
import { numbersPattern } from "../../shared/constants/regex";
import { ContactData } from "../servers/server.slice";
import {
    maxLengthLarge,
    maxLengthMedium,
    steamIdLength
} from "../../shared/constants/validation";
import { steamIdFinderUrl } from "../../shared/constants/urls";

const ContactForm: React.FC<FormStep> = ({
    completedHandler,
    nextStepHandler,
    backStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const schema = Yup.object().shape({
        contacts: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required(
                        translate("contactForm.nameRequired")
                    ),
                    country: Yup.string().required(
                        translate("contactForm.countryRequired")
                    ),
                    email: Yup.string()
                        .required(translate("contactForm.emailRequired"))
                        .email(translate("contactForm.emailInvalid")),
                    discordName: Yup.string(),
                    steamId: Yup.string()
                        .required(translate("contactForm.steamIdRequired"))
                        .length(
                            steamIdLength,
                            translate("contactForm.steamIdLength")
                        )
                        .matches(
                            numbersPattern,
                            translate("contactForm.steamIdLetters")
                        ),
                    forumName: Yup.string()
                })
            )
            .min(2, translate("contactForm.bothRequired"))
    });

    const initialValues: { contacts: ContactData[] } = {
        contacts: [
            {
                name: "",
                country: "",
                email: "",
                forumName: "",
                discordName: "",
                steamId: "",
                isPrimary: true
            },
            {
                name: "",
                country: "",
                email: "",
                discordName: "",
                steamId: ""
            }
        ]
    };

    const submitHandler = (values: { contacts: ContactData[] }) => {
        dispatch(setPrimaryContact(values.contacts[0]));
        dispatch(setAdditionalContacts([values.contacts[1]]));
        if (nextStepHandler) {
            nextStepHandler();
        }
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
        >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <DetectCompletedForm completedHandler={completedHandler} />

                    <FieldArray name="contacts">
                        {() => (
                            <>
                                {values.contacts.map((item, index) => (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        data-testid={`contact-${
                                            index === 0
                                                ? "primary"
                                                : "secondary"
                                        }`}
                                    >
                                        <h5>
                                            {index === 0
                                                ? translate(
                                                      "contactForm.primary"
                                                  )
                                                : translate(
                                                      "contactForm.secondary"
                                                  )}
                                        </h5>
                                        <Row>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId={`contactForm.name.${index}`}
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "contactForm.name"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field
                                                    name={`contacts.${index}.name`}
                                                >
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ContactData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label={`contacts-${index}-name-input`}
                                                                name={`contacts.${index}.name`}
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    item.name
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                md
                                                controlId={`contactForm.country.${index}`}
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "contactForm.country"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field
                                                    name={`contacts.${index}.country`}
                                                >
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ContactData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label={`contacts-${index}-country-input`}
                                                                name={`contacts.${index}.country`}
                                                                maxLength={
                                                                    maxLengthMedium
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    item.country
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                controlId={`contactForm.email.${index}`}
                                                md
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "contactForm.email"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field
                                                    name={`contacts.${index}.email`}
                                                >
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ContactData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label={`contacts-${index}-email-input`}
                                                                name={`contacts.${index}.email`}
                                                                maxLength={
                                                                    maxLengthLarge
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    item.email
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                controlId={`contactForm.discordName.${index}`}
                                                md
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "contactForm.discord"
                                                    )}
                                                </Form.Label>
                                                <Field
                                                    name={`contacts.${index}.discordName`}
                                                >
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ContactData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label={`contacts-${index}-discord-name-input`}
                                                                name={`contacts.${index}.discordName`}
                                                                maxLength={
                                                                    maxLengthLarge
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    item.discordName
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-2"
                                                as={Col}
                                                controlId={`contactForm.steamId.${index}`}
                                                md
                                            >
                                                <Form.Label>
                                                    {translate(
                                                        "contactForm.steamId"
                                                    )}{" "}
                                                    {translate(
                                                        "common.parenthesisOpen"
                                                    )}
                                                    <a
                                                        href={steamIdFinderUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {translate(
                                                            "contactForm.steamIdCo"
                                                        )}
                                                    </a>
                                                    {translate(
                                                        "common.parenthesisClose"
                                                    )}
                                                    {requiredFieldIcon}
                                                </Form.Label>
                                                <Field
                                                    name={`contacts.${index}.steamId`}
                                                >
                                                    {({
                                                        meta
                                                    }: {
                                                        meta: FieldMetaProps<ContactData>;
                                                    }) => (
                                                        <>
                                                            <Form.Control
                                                                aria-label={`contacts-${index}-steam-id-input`}
                                                                name={`contacts.${index}.steamId`}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    item.steamId
                                                                }
                                                                isInvalid={
                                                                    !!meta.touched &&
                                                                    !!meta.error
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {meta.error}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                md
                                                controlId={`contactForm.forumName.${index}`}
                                                className={`mb-2 ${
                                                    index > 0 ? "invisible" : ""
                                                }`}
                                            >
                                                {index === 0 && (
                                                    <Field
                                                        name={`contacts.${index}.forumName`}
                                                    >
                                                        {({
                                                            meta
                                                        }: {
                                                            // eslint-disable-next-line react/no-unused-prop-types
                                                            meta: FieldMetaProps<ContactData>;
                                                        }) => (
                                                            <>
                                                                <Form.Label>
                                                                    {translate(
                                                                        "contactForm.forum"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    aria-label={`contacts-${index}-forum-name-input`}
                                                                    name={`contacts.${index}.forumName`}
                                                                    maxLength={
                                                                        maxLengthMedium
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        item.forumName
                                                                    }
                                                                    isInvalid={
                                                                        !!meta.touched &&
                                                                        !!meta.error
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {meta.error}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        )}
                                                    </Field>
                                                )}
                                            </Form.Group>
                                        </Row>
                                    </div>
                                ))}
                            </>
                        )}
                    </FieldArray>
                    <div className="d-flex">
                        <Button
                            variant="secondary"
                            role="button"
                            type="button"
                            onClick={backStepHandler}
                        >
                            {chevronLeftIcon} {translate("common.back")}
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-auto"
                            role="button"
                            type="submit"
                        >
                            {translate("common.next")} {chevronRightIcon}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
