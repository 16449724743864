import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import GeneralForm from "../features/licenseApplication/GeneralForm";
import HardwareForm from "../features/licenseApplication/HardwareForm";
import { checkIcon } from "../shared/constants/unicodeIcons";
import style from "./ApplicationPage.module.scss";
import radix from "../shared/constants/radix";
import CommunityForm from "../features/licenseApplication/CommunityForm";
import AdminAvailabilityForm from "../features/licenseApplication/AdminAvailabilityForm";
import ContactForm from "../features/licenseApplication/ContactForm";
import ReviewApplication from "../features/licenseApplication/ReviewApplication";
import store from "../shared/store";
import Footer from "../shared/components/Footer";

export interface FormStep {
    completedHandler?: (isCompleted: boolean) => void;
    nextStepHandler?: () => void;
    backStepHandler?: () => void;
}

interface TabItem {
    component: React.FC<FormStep>;
    title: string;
    onComplete: boolean;
    completedHandler: FormStep["completedHandler"];
}

const ApplicationPage: React.FC = () => {
    const { t: translate } = useTranslation();

    const [step, setStep] = useState("0");

    const [generalCompleted, setGeneralCompleted] = useState(false);
    const [hardwareCompleted, setHardwareCompleted] = useState(false);
    const [communityCompleted, setCommunityCompleted] = useState(false);
    const [adminsCompleted, setAdminsCompleted] = useState(false);
    const [contactCompleted, setContactCompleted] = useState(false);
    const [reviewCompleted, setReviewCompleted] = useState(false);

    const getHeading = () => (
        <div className="text-center">
            <div className="d-lg-block d-none">
                <img src="logo.png" alt="Offworld Industries" />
                <h1>{translate("applicationPage.title")}</h1>
            </div>
            <div className="d-md-block d-lg-none">
                <img
                    src="logo.png"
                    alt="Offworld Industries"
                    className={style["image-small"]}
                />
                <h4>{translate("applicationPage.title")}</h4>
            </div>
        </div>
    );

    const tabList: TabItem[] = [
        {
            component: GeneralForm,
            title: translate("generalForm.tabTitle"),
            onComplete: generalCompleted,
            completedHandler: setGeneralCompleted
        },
        {
            component: HardwareForm,
            title: translate("hardwareForm.tabTitle"),
            onComplete: hardwareCompleted,
            completedHandler: setHardwareCompleted
        },
        {
            component: CommunityForm,
            title: translate("communityForm.tabTitle"),
            onComplete: communityCompleted,
            completedHandler: setCommunityCompleted
        },
        {
            component: AdminAvailabilityForm,
            title: translate("adminAvailabilityForm.tabTitle"),
            onComplete: adminsCompleted,
            completedHandler: setAdminsCompleted
        },
        {
            component: ContactForm,
            title: translate("contactForm.tabTitle"),
            onComplete: contactCompleted,
            completedHandler: setContactCompleted
        },
        {
            component: ReviewApplication,
            title: translate("reviewApplication.tabTitle"),
            onComplete: reviewCompleted,
            completedHandler: setReviewCompleted
        }
    ];

    const shouldSkipStep = (index: number) => {
        const hardwareIndex = tabList.findIndex(
            (item) => item.title === translate("hardwareForm.tabTitle")
        );
        // Have to access store directly because useSelector and useEffect are too slow
        const result =
            store.getState().licenseApplication.general !== null &&
            !store.getState().licenseApplication.general
                ?.hardwareProfileRequired &&
            index === hardwareIndex;
        return result;
    };

    const nextStepHandler: () => void = () => {
        setStep((prev) => {
            const index = parseInt(prev, radix);
            let offset = 0;
            if (shouldSkipStep(index + 1)) {
                offset = 1;
            }
            return `${index + 1 + offset}`;
        });
    };

    const backStepHandler = () => {
        setStep((prev) => {
            const index = parseInt(prev, radix);
            let offset = 0;
            if (shouldSkipStep(index - 1)) {
                offset = 1;
            }
            return `${index - 1 - offset}`;
        });
    };

    const getStepTitle = (
        number: number,
        title: string,
        isCompleted: boolean
    ) => (
        <span>
            {number + 1} <span className="d-lg-inline d-none">{title}</span>{" "}
            {isCompleted && !shouldSkipStep(number) ? checkIcon : ""}
        </span>
    );

    const getTabs = () => (
        <Tabs
            className="mb-2 justify-content-between"
            id="stepped-application-form"
            activeKey={step}
            onSelect={(eventKey) => eventKey && setStep(eventKey)}
            transition={false}
        >
            {tabList.map((item: TabItem, index) => (
                <Tab
                    key={item.title}
                    eventKey={index}
                    title={getStepTitle(index, item.title, item.onComplete)}
                    tabClassName={
                        shouldSkipStep(index) ? style["skip-step"] : ""
                    }
                    disabled
                >
                    <div data-testid={`tab-panel-${index}`}>
                        <item.component
                            completedHandler={item.completedHandler}
                            nextStepHandler={nextStepHandler}
                            backStepHandler={backStepHandler}
                        />
                    </div>
                </Tab>
            ))}
        </Tabs>
    );

    return (
        <div className="container w-100 mb-2">
            {getHeading()}
            {getTabs()}
            <Footer />
        </div>
    );
};

export default ApplicationPage;
