import { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../shared/store";
import { selectError, selectPromptLogin } from "./login.selectors";
import { promptLogin } from "./login.slice";
import Login from "./LoginForm";

const LoginPrompt: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const error = useSelector(selectError);
    const shouldPrompt = useSelector(selectPromptLogin);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(shouldPrompt);
    }, [shouldPrompt]);

    const handleClose = () => {
        dispatch(promptLogin(false));
    };

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="text-capitalize">
                    {translate("login.sessionExpired")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <p className="text-muted">{translate("login.loginAgain")}</p>
                {error && (
                    <Alert variant="danger">
                        {translate("login.unsuccessfulLogin", {
                            code: error.code
                        })}
                    </Alert>
                )}
                <Login />
            </Modal.Body>
        </Modal>
    );
};

export default LoginPrompt;
