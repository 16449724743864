import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { copyrightIcon } from "../constants/unicodeIcons";
import { privacyPolicyURL } from "../constants/urls";
import style from "./Footer.module.scss";

const Footer: React.FC = () => {
    const { t: translate } = useTranslation();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const year = DateTime.now().toLocaleString({ year: "numeric" });

    const getModal = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translate("legal.title")}</Modal.Title>
                </Modal.Header>

                <Modal.Body className={style["modal-container"]}>
                    <h4>{translate("legal.privacyPolicyTitle")}</h4>
                    <p>
                        <a
                            href={privacyPolicyURL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translate("legal.privacyPolicyText")}
                        </a>
                    </p>
                    <h4>{translate("legal.TermsOfUseTitle")}</h4>
                    <p>
                        <small>{translate("legal.lastUpdated")}</small>
                    </p>
                    <p>
                        {translate("legal.accessIsSubject", {
                            url: window.location.href
                        })}
                    </p>
                    <p>{translate("legal.adviceDisclaimer")}</p>
                    <p>{translate("legal.agreementList")}</p>
                    <ul>
                        <li>{translate("legal.agreeNoInfringe")}</li>
                        <li>{translate("legal.agreeOnLaws")}</li>
                        <li>{translate("legal.agreeNoAds")}</li>
                        <li>{translate("legal.agreeNoThreats")}</li>
                        <li>{translate("legal.agreeNoHack")}</li>
                        <li>{translate("legal.agreeNoSharePasswords")}</li>
                    </ul>
                    <p>{translate("legal.weMaySuspendAccess")}</p>
                    <h6>{translate("legal.trademarkTitle")}</h6>
                    <p>{translate("legal.trademarkAllRightsEeserved")}</p>
                    <p>{translate("legal.trademarkNonCommercialUse")}</p>
                    <p>{translate("legal.trademarkObscure")}</p>
                    <h6>{translate("legal.ideaSubmissionTitle")}</h6>
                    <p>{translate("legal.idealSubmissionText")}</p>
                    <h6>{translate("legal.errorTitle")}</h6>
                    <p>{translate("legal.errorNoWarranty")}</p>
                    <p>{translate("legal.errorInformation")}</p>
                    <h6>{translate("legal.thridPartyTitle")}</h6>
                    <p>{translate("legal.thridPartyReferences")}</p>
                    <h6>{translate("legal.limitationTile")}</h6>
                    <p>{translate("legal.limitationRisk")}</p>
                    <p>{translate("legal.limitationLaw")}</p>
                    <h6>{translate("legal.indemnityTitle")}</h6>
                    <p>{translate("legal.indemnifyText")}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {translate("common.close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <footer className="py-2 mt-3 border-top text-small" role="contentinfo">
            <Row className="d-flex align-items-center">
                <Col md={6}>
                    <small className="text-muted">
                        {copyrightIcon} {year} {translate("meta.author")}
                    </small>
                </Col>
                <Col md={6}>
                    <div className="d-md-flex justify-content-end">
                        <Button
                            className="text-muted px-0"
                            variant="link"
                            onClick={handleShow}
                        >
                            <small>{translate("legal.title")}</small>
                        </Button>
                    </div>
                </Col>
            </Row>
            {getModal()}
        </footer>
    );
};

export default Footer;
