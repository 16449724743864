import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { DateTime } from "luxon";
import { Col, Row, Card } from "react-bootstrap";
import { DateTimeAvailability } from "../licenseApplication/licenseApplication.selectors";

const AdminAvailabilityCard: React.FC<{
    data: DateTimeAvailability[];
}> = ({ data }) => {
    const { t: translate } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    {translate("adminAvailabilityForm.title")}
                </Card.Title>
                {data.map((item, index) => {
                    if (
                        Math.floor(item.duration.as("hours")) === 24 &&
                        item.weekends &&
                        item.weekdays
                    ) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Row key={index}>
                                <Col>
                                    {translate(
                                        "adminAvailabilityForm.isAlwaysAvailable"
                                    )}
                                </Col>
                            </Row>
                        );
                    }
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index}>
                            <Col>
                                {translate("reviewApplication.availability", {
                                    start: item.startTime.toLocaleString(
                                        DateTime.TIME_SIMPLE
                                    ),
                                    end: item?.endTime?.toLocaleString(
                                        DateTime.TIME_SIMPLE
                                    ),
                                    duration: Math.floor(
                                        item.duration.as("hours")
                                    )
                                })}{" "}
                                {item.weekdays && item.weekends
                                    ? translate("reviewApplication.everyday")
                                    : (item.weekdays &&
                                          translate(
                                              "reviewApplication.onWeekdays"
                                          )) ||
                                      (item.weekends &&
                                          translate(
                                              "reviewApplication.onWeekends"
                                          ))}
                            </Col>
                        </Row>
                    );
                })}
            </Card.Body>
        </Card>
    );
};

export default AdminAvailabilityCard;
