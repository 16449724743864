const apiVersion = "/api/v1";

const paths = {
    games: "games",
    licenses: "licenses",
    reports: "reports",
    servers: "servers",
    serverProviders: "server-providers",
    status: "status",
    users: "users",
    versions: "build.version"
} as const;

const endpoints = {
    game: {
        get: (id = "") =>
            id
                ? `${apiVersion}/${paths.games}/${id}`
                : `${apiVersion}/${paths.games}`,
        post: `${apiVersion}/${paths.games}`,
        put: (id: string) => `${apiVersion}/${paths.games}/${id}`
    },
    report: {
        get: (id: string) =>
            `${apiVersion}/${paths.reports}/licensed-server/${id}`
    },
    serverProvider: {
        get: (id = "") =>
            id
                ? `${apiVersion}/${paths.serverProviders}/${id}`
                : `${apiVersion}/${paths.serverProviders}`,
        post: `${apiVersion}/${paths.serverProviders}`,
        put: (id: string) => `${apiVersion}/${paths.serverProviders}/${id}`
    },
    server: {
        apply: `${apiVersion}/${paths.servers}/license-application`,
        search: `${apiVersion}/${paths.servers}/search`,
        get: (id: string) => `${apiVersion}/${paths.servers}/${id}`,
        patch: (id: string) => `${apiVersion}/${paths.servers}/${id}`
    },
    status: {
        get: `${apiVersion}/${paths.status}`
    },
    license: {
        regenKeys: (id: string) =>
            `${apiVersion}/${paths.licenses}/${id}/regenerate-keys`,
        changeStatus: ({ id, action }: { id: string; action: string }) =>
            `${apiVersion}/${paths.licenses}/${id}/${action}`
    },
    user: {
        login: `${apiVersion}/${paths.users}/login/internal`,
        logout: `${apiVersion}/${paths.users}/logout`,
        refreshToken: `${apiVersion}/tokens/refresh`
    },
    version: {
        // leading slash (/) is a special case for s3 bucket
        get: `/${paths.versions}`
    }
} as const;

export default endpoints;
