import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { AppDispatch } from "../../shared/store";
import { useAppSelector } from "../../shared/hooks";
import { search } from "./server.slice";
import { selectSearchQuery } from "./server.selectors";
import { searchQueryOffset } from "../../shared/constants/searchQuery";

const ShowMoreResults: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const searchQuery = useAppSelector(selectSearchQuery);

    const showMoreHandler = () => {
        dispatch(
            search({
                ...searchQuery,
                offset: (searchQuery.offset || 0) + searchQueryOffset
            })
        );
    };

    return (
        <Button variant="outline-secondary" onClick={showMoreHandler}>
            {translate("searchResults.showMoreResults")}
        </Button>
    );
};

export default ShowMoreResults;
