import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useDispatch } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { setCommunity } from "./licenseApplication.slice";
import { AppDispatch } from "../../shared/store";
import DetectCompletedForm from "../../shared/components/DetectCompletedForm";
import {
    chevronLeftIcon,
    chevronRightIcon,
    requiredFieldIcon
} from "../../shared/constants/unicodeIcons";
import { FormStep } from "../../pages/ApplicationPage";
import { CommunityData } from "../servers/server.slice";
import { startsWithHttp, urlOptionalHttp } from "../../shared/constants/regex";
import {
    maxLengthLarge,
    maxLengthMedium,
    maxLengthSmall,
    maxSizeNumber
} from "../../shared/constants/validation";

const CommunityForm: React.FC<FormStep> = ({
    completedHandler,
    nextStepHandler,
    backStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const schema = Yup.object().shape({
        name: Yup.string().required(translate("common.nameRequired")),
        size: Yup.number()
            .integer(translate("common.integerRequired"))
            .moreThan(-1, translate("communityForm.sizeLessThanZero"))
            .lessThan(maxSizeNumber, translate("communityForm.sizeOver")),
        url: Yup.string()
            .required(translate("communityForm.urlRequired"))
            .matches(urlOptionalHttp, translate("communityForm.urlInvalid"))
    });

    const initialValues: CommunityData = {
        name: "",
        size: 0,
        tag: "",
        url: ""
    };

    const submitHandler = (values: CommunityData) => {
        const data = {
            ...values,
            url: `${values.url.match(startsWithHttp) ? "" : "http://"}${
                values.url
            }`
        };
        dispatch(setCommunity(data));
        if (nextStepHandler) {
            nextStepHandler();
        }
    };

    return (
        <div>
            <h5>{translate("communityForm.title")}</h5>
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={submitHandler}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <DetectCompletedForm
                            completedHandler={completedHandler}
                        />
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md={6}
                                controlId="communityForm.name"
                            >
                                <Form.Label>
                                    {translate("communityForm.name")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="community-name-input"
                                    type="text"
                                    name="name"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="communityForm.tag"
                            >
                                <Form.Label>
                                    {translate("communityForm.tag")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="community-tag-input"
                                    type="string"
                                    name="tag"
                                    maxLength={maxLengthSmall}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tag}
                                    isInvalid={!!touched.tag && !!errors.tag}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.tag}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                md
                                controlId="communityForm.size"
                            >
                                <Form.Label>
                                    {translate("communityForm.size")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="community-size-input"
                                    type="number"
                                    name="size"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.size}
                                    isInvalid={!!touched.size && !!errors.size}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.size}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                className="mb-2"
                                as={Col}
                                controlId="communityForm.url"
                            >
                                <Form.Label>
                                    {translate("communityForm.url")}
                                    {requiredFieldIcon}
                                </Form.Label>
                                <Form.Control
                                    aria-label="community-url-input"
                                    type="text"
                                    name="url"
                                    maxLength={maxLengthLarge}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.url}
                                    isInvalid={!!touched.url && !!errors.url}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="d-flex">
                            <Button
                                variant="secondary"
                                role="button"
                                type="button"
                                onClick={backStepHandler}
                            >
                                {chevronLeftIcon} {translate("common.back")}
                            </Button>
                            <Button
                                variant="primary"
                                className="ms-auto"
                                role="button"
                                type="submit"
                            >
                                {translate("common.next")} {chevronRightIcon}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CommunityForm;
