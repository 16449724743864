export const TRANSLATIONS_EN_US = {
    common: {
        addNew: "Add New",
        appTitle: "OWI License Server",
        back: "Back",
        cancel: "Cancel",
        choose: "...",
        close: "Close",
        disabled: "Disabled",
        enabled: "Enabled",
        error: "Error",
        errorMessage: "{{status}} (Code: {{code}}).",
        id: "ID",
        loading: "Loading ...",
        logout: "Logout",
        name: "Name",
        nameRequired: "name is required",
        notApplicable: "N/A",
        integerRequired: "invalid number (must be a positive integer)",
        parentheses: "({{value}})",
        parenthesisClose: ")",
        parenthesisOpen: "(",
        next: "Next",
        requiredFieldInfo: "Labels marked with {{icon}} are required.",
        save: "Save",
        searchPlaceholder: "Search ...",
        unknown: "unknown"
    },
    adminAvailabilityForm: {
        add: "Add New Time",
        isAlwaysAvailable: "An admin is always available",
        duration: "({{hours}} hours)",
        durationOvernight: "({{hours}} hours, overnight)",
        endTime: "End Time",
        endTimeRequired: "end time is required",
        info: "Please enter the approximate time that an admin is available on your server. Admins should be available during peak hours on the server.",
        or: "Or choose specific hours of availability",
        partOfWeekRequired: "at least one option is required",
        remove: "Remove",
        startTime: "Start Time",
        startTimeRequired: "start time is required",
        tabTitle: "Availability",
        title: "Availability",
        weekdays: "Weekdays",
        weekends: "Weekends"
    },
    applicationPage: {
        title: "License Server Application"
    },
    communityForm: {
        name: "Community Name",
        size: "Approximate Size",
        sizeLessThanZero: "size must be a positive number",
        sizeOver: "size must be under 1 billion",
        tabTitle: "Community",
        tag: "Community Tag",
        title: "Community",
        url: "Community website or Discord server URL",
        urlShort: "Community URL",
        urlInvalid: "invalid URL",
        urlRequired: "URL is required"
    },
    contactForm: {
        bothRequired: "primary and secondary contacts are required",
        country: "Country",
        countryRequired: "country is required",
        discord: "Discord Name",
        email: "Email",
        emailInvalid: "invalid email",
        emailRequired: "email is required",
        forum: "Forum Name",
        name: "Contact Name",
        nameRequired: "contact name is required",
        primary: "Primary Contact",
        secondary: "Secondary Contact",
        steamId: "Steam 64 ID",
        steamIdCo: "Steamid.co",
        steamIdLetters: "invalid steam 64 id (can not contain letters)",
        steamIdRequired: "steam 64 id is required",
        steamIdLength: "invalid steam 64 id (must be 17 numbers)",
        tabTitle: "Contact",
        title: "Contact Info"
    },
    errorAlert: {
        code: "Code: {{code}}",
        login: "Login",
        loginRequired: "Login required to complete this action.",
        status: "Status: {{status}}",
        unknownCode: "Unknown Code",
        unknownStatus: "Unknown Status"
    },
    legal: {
        title: "Privacy Policy and Terms of Use",
        privacyPolicyTitle: "Privacy Policy",
        privacyPolicyText: "Click here to read our privacy policy.",
        TermsOfUseTitle: "Terms of Use",
        lastUpdated: "Last updated: January 31, 2019",
        accessIsSubject:
            "Your access to and the use of {{url}} (the “Website”) is subject to the following terms and conditions, as well as all applicable laws. In consideration of Offworld Industries Ltd. (“we” or “us”) making the Website available to you, you agree to comply by these Terms of Use, as amended from time to time. If you do not agree, do not use the Website.",
        adviceDisclaimer:
            "IMPORTANT INFORMATION: The Website is for information purposes only and does not constitute professional advice on any subject matter. All information contained herein, while obtained from sources which are believed to be reliable, is not guaranteed as to its accuracy or completeness, and is provided without warranty of any kind.",
        agreementList: "You agree that you will not:",
        agreeNoInfringe:
            "Upload or transmit any communications or content of any type that may infringe or violate any rights of any party.",
        agreeOnLaws:
            "Use this Website for any purpose in violation of local, provincial, national or international laws.",
        agreeNoAds:
            "Use this Website as a means to distribute advertising or other unsolicited material to any third party.",
        agreeNoThreats:
            "Use this Website to post or transmit material that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful or embarrassing to any other person or entity.",
        agreeNoHack:
            "Attempt to disable, “hack” or otherwise interfere with the proper functioning of this Website.",
        agreeNoSharePasswords:
            "If you use any part of the Website requiring secure access, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.",
        weMaySuspendAccess:
            "We may (in our sole discretion) suspend/terminate your access to or use of the Website without notification for any violation of these Terms of Use, or for other conduct that we deem harmful/unlawful to others. In the event of termination, you are no longer authorized to access the Website, and we will use whatever means necessary to enforce this termination.",
        trademarkTitle: "COPYRIGHT/TRADEMARK NOTICE",
        trademarkAllRightsEeserved:
            "All of our trademarks are protected by Canadian law. Unauthorized use of our trademarks is strictly prohibited. All other trademarks, titles, content, company names, artwork, logos and associated imagery are trademarks and/or copyright material of their respective owners. All rights reserved.",
        trademarkNonCommercialUse:
            "All Website design, text, layout, graphics, and the selection and arrangement thereof is owned by us, our affiliates, and all rights are reserved. You are authorized to view, download and print portions of the Website solely for your personal and non-commercial use or as we otherwise permit. Any other use of materials on this Website, including: (1) reproduction, except as authorized herein; (2) modifications; (3) distributions; (4) republication; (5) transmission; (6) re-transmissions; (7) or public showing, without our prior written permission, is strictly prohibited.",
        trademarkObscure:
            "You may not remove or obscure the copyright notice or other notices contained in information or products retrieved from this Website.",
        ideaSubmissionTitle: "IDEA SUBMISSION STATEMENT",
        idealSubmissionText:
            "Any ideas, proposals, and/or other information submitted or collected on this Website shall be considered non-confidential. By submitting information to this Website, or directly to us, you agree that we shall not be under any obligation of confidentiality or non-use, express or implied, with respect to any submissions, and we may disclose or use any submission for any purpose whatsoever, at our sole discretion, with no obligation, express or implied, to compensate or work with the person or entity that supplied the information, idea and/or proposal in any manner.",
        errorTitle: "ERRORS",
        errorNoWarranty:
            "The content of the Website is for your general information and use only. It is subject to change without notice. While information provided is intended to be accurate, no representation or warranty is made with respect to the accuracy or reliability of such information.",
        errorInformation:
            "We endeavor to display all information correctly, but occasionally the information provided may not be accurate. Product, service and other information provided are subject to change without notice. Errors will be corrected where discovered, and we reserve the right to revoke any stated offer and to correct any errors, inaccuracies or omissions. Please let us know immediately if you become aware of any errors in the Website.",
        thridPartyTitle: "THIRD PARTY MATERIAL",
        thridPartyReferences:
            "The Website may, from time to time, contain, link to or reference third party websites, resources and advertisers (collectively, “Third Party Material”). Accessing such Third Party Material is at your own risk. We are not responsible for the accuracy or reliability of any Third Party Material. We do not investigate, monitor, or check Third Party Material for accuracy or completeness. We are not responsible for the availability of Third Party Material, and are providing it to you only as a convenience and may discontinue providing it at any time in our sole discretion without notice to you. No endorsement of any third party content, information, data, opinions, advice, statements, goods, services or products is expressed or implied by any Third Party Material.",
        limitationTile: "LIMITATION OF LIABILITY",
        limitationRisk:
            " Your use of any information or materials on the Website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information meet your specific requirements. To the full extent permitted by law, we disclaim any warranty as to the Website and the suitability of the content therein.",
        limitationLaw:
            "UNLESS PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES WILL WE, OUR OFFICERS, DIRECTORS, EMPLOYEES OR REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM OR RELATING TO THE WEBSITE OR THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUES, LOSS OF PROFITS, BUSINESS INTERRUPTION OR LOSS OF DATA, ARISING OUT OF OR IN CONNECTION WITH THE WEBSITE INCLUDING, WITHOUT LIMITATION, (1) THE USE OF OR INABILITY TO USE THE WEBSITE; (2) ANY CONTENTS OR MATERIALS INCLUDED IN THE WEBSITE; (3) ANY INACCURACY, ERROR OR OMISSION ASSOCIATED WITH THE WEBSITE, (4) ANY WORK INITIATED BY YOU AND BASED ON THE DIRECTIONS CONTAINED IN THE WEBSITE OR RESULTING DIRECTLY OR INDIRECTLY FROM THE USE OF THE INFORMATION CONTAINED IN THE WEBSITE; AND (5) ANY TRANSACTION CONDUCTED IN CONNECTION WITH THE WEBSITE.",
        indemnityTitle: "INDEMNITY",
        indemnifyText:
            "You agree to indemnify Offworld Industries Ltd. and any of its affiliated companies and each of their respective their owners, officers, directors, employees, agents, and licensors from any claim or demand, including reasonable legal fees, made by any third party due to content you submit or transmit through the Website, your use of the Website, your violation of these Terms of Use, or your violation of any rights of another."
    },
    login: {
        button: "Login",
        emailLabel: "Email",
        passwordLabel: "Password",
        usernameRequired: "email is required",
        loginAgain: "Log in again to continue",
        sessionExpired: "Session expired",
        passwordRequired: "password is required",
        title: "License Server Login",
        unsuccessfulLogin: "Login Unsuccessful (Code: {{code}})."
    },
    meta: {
        title: "OWI - License Server",
        author: "Offworld Industries",
        copyright: "Offworld Industries",
        description:
            "Apply to get an offically licensed game server from Offworld Industries by using the License Server Application Form. If approved, your server will be listed on the server browser for players to join."
    },
    navLinks: {
        games: "Games",
        licenses: "Licenses",
        providers: "Providers"
    },
    games: {
        agreementUrl: "Agreement Url",
        error: "Error processing games",
        errorMessage: "{{status}} (Code: {{code}}).",
        licenseAgreementUrl: "License Agreement URL",
        licenseAgreementUrlInvalid: "Invalid URL",
        licenseAgreementUrlRequired: "URL is required",
        newGame: "New Game",
        noneFound: "No games found",
        title: "Games",
        titleWithName: "Games / {{name}}"
    },
    generalForm: {
        acceptTerms: "I accept the terms and conditions of the",
        acceptTermsRequired: "accepting the terms and conditions is required",
        additionalComments: "Additional Comments",
        additionalCommentsInfo:
            "If you are reapplying you can use this section to include the reason for the reapplication.",
        agreement: "License Agreement",
        availableAdmins: "Available Admins",
        availableAdminsRequired: "available admins is required",
        availableAdminsUnder: "most games require at least 5 or more admins",
        availableAdminsOver: "size must be under 1 billion",
        game: "Game",
        gameRequired: "game is required",
        ipAddress: "IP Address",
        ipAddressRequired: "IP address is required",
        ipAddressInvalid: "invalid IP address",
        languagesList: "Language(s)",
        localesUnder: "at least one language is required",
        localesOver: "must have less than 10 languages",
        playerSlots: "Player Slots",
        playerSlotsOver: "most games max out at 100 players",
        playerSlotsRequired: "player slots is required",
        playerSlotsUnder: "most games require at least 60 players",
        serverName: "Server Name",
        serverNameRequired: "server name is required",
        serverProvider: "Server Provider",
        serverProviderRequired: "server provider is required",
        title: "General Info",
        tabTitle: "General"
    },
    licenses: {
        error: "Error processing licenses",
        lastModified: "Last Modified",
        license: "License",
        title: "Licenses",
        titleWithName: "Licenses / {{name}}",
        regenConfirm: "Yes, Regenerate Keys",
        regenKeys: "Regenerate Keys",
        regenWarning:
            "Are you sure that you want to regenerate the keys for this server? The current keys will be invalidated and new keys will be sent to the primary contact's email.",
        regenKeysFulfilled: "Keys Regenerated!"
    },
    reviewApplication: {
        apply: "Apply",
        availability: "From {{start}} to {{end}} ({{duration}} hours)",
        completed: "Completed",
        error: "Error processing application",
        everyday: "everyday",
        info: "Before submitting your application, please take a moment to review the information that you have provided. Click back to edit.",
        submitting: "Submitting",
        successTitle: "Your form was submitted successfully!",
        successMessage:
            "Thank you for applying. We will review your application and get back to you by email.",
        tabTitle: "Review",
        title: "Review & Apply",
        onWeekdays: "on weekdays",
        onWeekends: "on weekends"
    },
    licenseStatusChange: {
        active: "Active",
        applied: "Applied",
        approve: "Approve",
        changeStatusTo: "Change Status to",
        confirmTitle: "Confirm Status Change",
        disable: "Disable",
        disabled: "Disabled",
        enable: "Enable",
        noActions: "No actions available",
        reject: "Reject",
        rejected: "Rejected",
        revoke: "Revoke",
        revoked: "Revoked",
        server: "Server",
        statusChange: "Status Change",
        statusChangeReason: "Status Change Reason",
        statusChangeReasonLabel: "Status Change Reason (Optional)",
        unknown: "Unknown Status"
    },
    serverReport: {
        badHealth: "Bad",
        error: "Error Getting Report",
        tickrate: "Tickrate",
        gameVersion: "Game Version",
        goodHealth: "Good",
        ipAddress: "IP Address",
        loading: "Loading Report ...",
        nearFull: "Near Full",
        no: "No",
        noDataFound: "No report data found",
        okHealth: "Ok",
        passwordProtected: "Password Protected",
        playerCount: "Player Count",
        reportTimestamp: "Report Date",
        serverPort: "Server Port",
        serverReport: "Server Report",
        unknownHealth: "Unknown",
        yes: "Yes"
    },
    searchForm: {
        clear: "Clear Filters",
        contactEmail: "Contact Email",
        contactName: "Contact Name",
        communityName: "Community Name",
        game: "Game",
        gameDisabled: "(disabled)",
        licenseNumber: "License Number",
        licenseNumberInvalid: "must contain numbers only",
        licenseStatuses: "Status",
        name: "Name",
        contactSteamId: "Steam ID",
        contactSteamIdInvalid: "must be exactly 17 numbers",
        search: "Search",
        showLess: "Less Filters",
        showMore: "More Filters",
        sortBy: "Sort By",
        status: "Status"
    },
    searchResults: {
        results: "Results",
        licenseNumberHeader: "#",
        nameHeader: "Server",
        gameHeader: "Game",
        contactNameHeader: "Contact",
        contactEmailHeader: "Email",
        communityNameHeader: "Community",
        noResults:
            "No results found. Try searching again using different filters.",
        showMoreResults: "Show More Results",
        statusHeader: "Status"
    },
    servers: {
        details: "Details",
        edit: "Edit",
        error: "Error processing servers"
    },
    hardwareForm: {
        cpuFrequency: "CPU frequency",
        cpuFrequencyPlaceholder: "3.7GHz",
        cpuFrequencyRequired: "CPU frequency is required",
        cpuModel: "CPU Model",
        cpuModelPlaceholder: "AMD Ryzen 5 5600X",
        cpuModelRequired: "CPU model is required",
        diskDrives: "Disk Drives",
        diskDrivesPlaceholder: "500GB SSD",
        diskDrivesRequired: "disk drives is required",
        hostingCompany: "Hosting Company",
        hostingCompanyPlaceholder: "",
        location: "Location",
        locationPlaceholder: "Boston",
        locationRequired: "location is required",
        networkSpeed: "Network Speed",
        networkSpeedPlaceholder: "3 Mbps",
        networkSpeedRequired: "network speed is required",
        operatingSystem: "Operating System",
        operatingSystemPlaceholder: "Windows 10 Pro",
        operatingSystemRequired: "operating system is required",
        physicalMemory: "Physical Memory",
        physicalMemoryPlaceholder: "32GB DDR4",
        physicalMemoryRequired: "physical memory is required",
        title: "Hardware Profile",
        tabTitle: "Hardware"
    },
    serverProviders: {
        error: "Error processing server provider",
        hardwareProfile: "Hardware Profile",
        noneFound: "No server providers found",
        notRequired: "Not Required",
        required: "Required",
        title: "Providers",
        titleWithName: "Providers / {{name}}"
    },
    version: {
        apiVersion: "API Version",
        clientVersion: "Client Version",
        status: "Status",
        title: "Client and API Versions",
        errorApiVersion: "Error getting API version",
        errorClientVersion: "Error getting client version",
        version: "Version:"
    }
};
