import { useState } from "react";
import { GoKey } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { AppDispatch } from "../../shared/store";
import { selectLoading } from "./license.selectors";
import { httpStatus } from "../../shared/api/api";
import { regenKeys } from "./license.slice";

const RegenerateKeys: React.FC<{ id?: string }> = ({ id }) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const [showModal, setShowModal] = useState(false);
    const [regenSuccess, setRegenSuccess] = useState(false);
    const closeModalHandler = () => setShowModal(false);
    const openModalHandler = () => setShowModal(true);

    const regenHandler = async () => {
        if (!id) {
            return;
        }
        closeModalHandler();
        const resultAction = await dispatch(regenKeys(id));
        if (regenKeys.fulfilled.match(resultAction)) {
            setRegenSuccess(true);
            setTimeout(() => {
                setRegenSuccess(false);
            }, 3000);
        }
    };

    const getConfirmModal = () => (
        <Modal show={showModal} onHide={closeModalHandler} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{translate("licenses.regenKeys")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{translate("licenses.regenWarning")}</p>
                <div className="d-flex d-flex justify-content-end">
                    <Button
                        variant="outline-secondary"
                        className="me-1"
                        onClick={closeModalHandler}
                    >
                        {translate("common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={regenHandler}>
                        {translate("licenses.regenConfirm")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );

    return (
        <>
            <Button
                variant={regenSuccess ? "success" : "secondary"}
                onClick={openModalHandler}
                disabled={loading === httpStatus.pending || !id}
                style={{ transition: "background-color 1s" }}
            >
                <GoKey className="me-1" />
                {regenSuccess
                    ? translate("licenses.regenKeysFulfilled")
                    : translate("licenses.regenKeys")}
            </Button>
            {getConfirmModal()}
        </>
    );
};

export default RegenerateKeys;
