import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
    selectError,
    selectLoading,
    selectSingle
} from "../features/serverProviders/serverProvider.selectors";
import ServerProviderForm from "../features/serverProviders/ServerProviderForm";
import { httpStatus } from "../shared/api/api";
import { newProviderId } from "../features/serverProviders/serverProvider.slice";
import LoadingSpinner from "../shared/components/LoadingSpinner";
import ErrorAlert from "../shared/components/ErrorAlert";
import Title from "../shared/components/Title";

const ServerProviderDetailPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const data = useSelector(selectSingle);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const getTitle = () => {
        let title = "";
        if (data?.name) {
            title = data.name;
        }
        if (data?.id === newProviderId) {
            title = translate("common.addNew");
        }
        if (loading === httpStatus.pending) {
            title = "";
        }
        return (
            <div className="d-flex align-items-center">
                <Title
                    title={translate("serverProviders.titleWithName", {
                        name: title
                    })}
                    classes="me-2"
                />
                {loading === httpStatus.pending && <LoadingSpinner />}
            </div>
        );
    };

    return (
        <Container>
            <div className="d-flex flex-column w-100">
                {getTitle()}
                <ErrorAlert
                    heading={translate("serverProviders.error")}
                    isShown={!!error}
                    details={error}
                />
                <ServerProviderForm />
            </div>
        </Container>
    );
};

export default ServerProviderDetailPage;
