import { useEffect } from "react";
import { useFormikContext } from "formik";

const DetectCompletedForm: React.FC<{
    completedHandler?: (isCompleted: boolean) => void;
}> = ({ completedHandler }) => {
    const { touched, errors, submitCount } = useFormikContext();
    useEffect(() => {
        const touchedCount = Object.keys(touched).length;
        const errorsCount = Object.keys(errors).length;
        if (touchedCount > 0 && errorsCount === 0 && submitCount > 0) {
            completedHandler?.(true);
        } else {
            completedHandler?.(false);
        }
    }, [completedHandler, touched, errors, submitCount]);
    return null;
};

export default DetectCompletedForm;
