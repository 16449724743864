import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";

const namespace = "license";

export const licenseStatus = {
    applied: "applied",
    rejected: "rejected",
    revoked: "revoked",
    disabled: "disabled",
    active: "active"
} as const;

export const validKeyRegenerationStatuses: Array<string> = [
    licenseStatus.active,
    licenseStatus.disabled
];

export const licenseAction = {
    approve: "approve",
    reject: "reject",
    revoke: "revoke",
    disable: "disable",
    enable: "enable"
} as const;

export const statusStyle = {
    applied: "warning",
    active: "success",
    inactive: "danger",
    unknown: "light"
} as const;

export interface LicenseAction {
    id: string;
    action: string;
    reason?: string;
}

export interface License {
    loading: string;
    error: AppError | null;
}

const initialState: License = {
    loading: httpStatus.idle,
    error: null
};

export const statusTransitions = {
    getActions: (status?: string): string[] => {
        switch (status) {
            case licenseStatus.applied: {
                return [licenseAction.approve, licenseAction.reject];
            }
            case licenseStatus.disabled: {
                return [licenseAction.enable, licenseAction.revoke];
            }
            case licenseStatus.active: {
                return [licenseAction.disable, licenseAction.revoke];
            }
            case licenseStatus.rejected:
            case licenseStatus.revoked:
            default: {
                return [];
            }
        }
    },
    getStatus: (action?: string): string => {
        switch (action) {
            case licenseAction.enable:
            case licenseAction.approve: {
                return licenseStatus.active;
            }
            case licenseAction.reject: {
                return licenseStatus.rejected;
            }
            case licenseAction.revoke: {
                return licenseStatus.revoked;
            }
            case licenseAction.disable: {
                return licenseStatus.disabled;
            }
            default: {
                return "unknown";
            }
        }
    },
    getStyle: (status: string): string => {
        switch (status?.toLowerCase()) {
            case licenseStatus.applied: {
                return statusStyle.applied;
            }
            case licenseStatus.active: {
                return statusStyle.active;
            }
            case licenseStatus.rejected:
            case licenseStatus.revoked:
            case licenseStatus.disabled: {
                return statusStyle.inactive;
            }
            default: {
                return statusStyle.unknown;
            }
        }
    },
    isReasonMessageHidden: (action: string): boolean => {
        return action === licenseAction.approve;
    }
};

export const regenKeys = createAsyncThunk<
    null,
    string,
    { rejectValue: AppError }
>(`${namespace}/regenKeys`, async (id: string, { rejectWithValue }) => {
    try {
        return await api.post<unknown, null>(
            endpoints.license.regenKeys(id),
            {}
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export const changeStatus = createAsyncThunk<
    null,
    LicenseAction,
    { rejectValue: AppError }
>(`${namespace}/changeStatus`, async (values, { rejectWithValue }) => {
    try {
        return await api.post<{ reason?: string }, null>(
            endpoints.license.changeStatus(values),
            {
                ...(values.reason ? { reason: values.reason } : {})
            }
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const licenseApplicationSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(regenKeys.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(regenKeys.fulfilled, (state) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
        });
        builder.addCase(regenKeys.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(changeStatus.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(changeStatus.fulfilled, (state) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
        });
        builder.addCase(changeStatus.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default licenseApplicationSlice.reducer;
