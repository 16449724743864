import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Col, Row, Card } from "react-bootstrap";
import KeyValue from "../../shared/components/KeyValue";
import { CommunityData } from "./server.slice";

const CommunityCard: React.FC<{ data: CommunityData }> = ({ data }) => {
    const { t: translate } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Card.Title>{translate("communityForm.title")}</Card.Title>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("communityForm.name")}
                            value={data.name}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("communityForm.size")}
                            value={data.size}
                        />
                    </Col>
                    <Col md={6}>
                        <KeyValue
                            keyName={translate("communityForm.tag")}
                            value={data.tag}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <div className="mb-2">
                            <span className="text-muted">
                                {translate("communityForm.urlShort")}
                            </span>
                            <br />
                            {data.url && (
                                <a
                                    href={data.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-flex align-items-center text-break"
                                >
                                    {data.url}
                                    <BsBoxArrowUpRight className="ms-2" />
                                </a>
                            )}
                            {!data.url && (
                                <span>{translate("common.notApplicable")}</span>
                            )}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CommunityCard;
