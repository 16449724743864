export interface NavLink {
    label: string;
    route: string;
    protected: boolean;
}

const navLinks: readonly NavLink[] = [
    {
        label: "licenses",
        route: "/admin/licenses",
        protected: true
    },
    {
        label: "providers",
        route: "/admin/providers",
        protected: true
    },
    {
        label: "games",
        route: "/admin/games",
        protected: true
    },
    {
        label: "apply",
        route: "/",
        protected: false
    },
    {
        label: "login",
        route: "/login",
        protected: false
    }
];

export default navLinks;
