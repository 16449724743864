import axios, { AxiosRequestConfig, AxiosError } from "axios";
import httpClient from "./httpClient";

export const httpStatus = {
    pending: "pending",
    fulfilled: "fulfilled",
    rejected: "rejected",
    idle: "idle"
} as const;

const httpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    put: "PUT"
} as const;

export const getTokenPrefix = (token: string): string => `Bearer ${token}`;

export interface ApiRequest extends AxiosRequestConfig {
    isAuth?: boolean;
    isRetry?: boolean;
    interceptorRejectReason?: string;
}

const request = async <Response, Body = void>(
    config: ApiRequest,
    body?: Body
): Promise<Response> => {
    const newConfig: ApiRequest = {
        ...config,
        ...(body ? { data: body } : {})
    };
    const response = await httpClient
        .request<Response>(newConfig)
        .catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
                throw error.response?.data;
            } else {
                throw error;
            }
        });
    return response.data;
};

export const apiNoAuth = {
    get: <Response>(url: string): Promise<Response> =>
        request<Response>({ url }),

    post: <Body, Response>(url: string, body: Body): Promise<Response> =>
        request<Response, Body>({ url, method: httpMethod.post }, body)
};

export const api = {
    get: <Response>(url: string): Promise<Response> =>
        request<Response>({ url, isAuth: true }),

    getRefreshAuth: <Response>(url: string): Promise<Response> =>
        request<Response>({ url, isAuth: true }),

    post: <Body, Response>(url: string, body: Body): Promise<Response> =>
        request<Response, Body>(
            { url, isAuth: true, method: httpMethod.post },
            body
        ),

    put: <Body, Response>(url: string, body: Body): Promise<Response> =>
        request<Response, Body>(
            { url, isAuth: true, method: httpMethod.put },
            body
        ),

    patch: <Body, Response>(url: string, body: Body): Promise<Response> =>
        request<Response, Body>(
            { url, isAuth: true, method: httpMethod.patch },
            body
        )
};
