import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN_US } from "./en/us";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en_US",
        interpolation: {
            escapeValue: false
        },
        keySeparator: ".",
        resources: {
            en_US: {
                translation: TRANSLATIONS_EN_US
            }
        }
    });
