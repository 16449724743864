import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import SearchResults from "../features/servers/SearchResults";
import SearchForm from "../features/servers/SearchForm";
import {
    selectList,
    selectLoading,
    selectError as selectServerError,
    selectSearchQuery
} from "../features/servers/server.selectors";
import { httpStatus } from "../shared/api/api";
import { selectError as selectLicenseError } from "../features/licenses/license.selectors";
import Title from "../shared/components/Title";
import LoadingSpinner from "../shared/components/LoadingSpinner";
import ErrorAlert from "../shared/components/ErrorAlert";
import ShowMoreResults from "../features/servers/ShowMoreResults";

const ServersListPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const searchQuery = useSelector(selectSearchQuery);
    const data = useSelector(selectList);
    const loading = useSelector(selectLoading);
    const serverError = useSelector(selectServerError);
    const licenseError = useSelector(selectLicenseError);

    const getTitle = () => (
        <div className="d-flex align-items-center">
            <Title title={translate("licenses.title")} classes="me-2" />
            {loading === httpStatus.pending && <LoadingSpinner />}
        </div>
    );

    const showMore = () => {
        const expectedLength =
            (searchQuery?.limit || 0) * ((searchQuery?.offset || 0) + 1);
        const actualLength = data?.length || 0;

        return expectedLength <= actualLength && actualLength > 0;
    };

    return (
        <Container>
            <div className="d-flex flex-column w-100 mb-2">
                {getTitle()}
                <ErrorAlert
                    heading={translate("licenses.error")}
                    isShown={!!serverError || !!licenseError}
                    details={serverError || licenseError}
                />
                <div className="mb-2">
                    <SearchForm />
                </div>
                {data && data.length > 0 && <SearchResults />}
                {loading === httpStatus.fulfilled && data?.length === 0 && (
                    <p className="text-muted mt-2">
                        {translate("searchResults.noResults")}
                    </p>
                )}
                <div className="d-flex justify-content-center w-100">
                    {showMore() && <ShowMoreResults />}
                </div>
            </div>
        </Container>
    );
};

export default ServersListPage;
