export const maxLengthSmall = 20;
export const maxLengthMedium = 100;
export const maxLengthLarge = 255;
export const maxLengthExtraLarge = 300;
export const maxSizeNumber = 1000000000;
export const minAdmins = 4;
export const maxPlayerSlots = 100;
export const minPlayerSlots = 60;
export const steamIdLength = 17;
export const maxLocales = 10;
