import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";

const namespace = "apiVersion";

export interface ApiVersionData {
    buildVersion: string;
    status: string;
}

export interface ApiVersion {
    loading: string;
    data: ApiVersionData | null;
    error: AppError | null;
}

const initialState: ApiVersion = {
    loading: httpStatus.idle,
    data: null,
    error: null
};

export const getApiVersion = createAsyncThunk<
    ApiVersionData,
    void,
    { rejectValue: AppError }
>(`${namespace}/getApiVersion`, async (_, { rejectWithValue }) => {
    try {
        return await apiNoAuth.get<ApiVersionData>(endpoints.status.get);
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const apiVersionSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getApiVersion.pending, (state) => {
            state.loading = httpStatus.pending;
            state.data = null;
            state.error = null;
        });
        builder.addCase(getApiVersion.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.data = payload as ApiVersionData;
            state.error = null;
        });
        builder.addCase(getApiVersion.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default apiVersionSlice.reducer;
