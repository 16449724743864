import { RootState } from "../../shared/store";
import AppError from "../../shared/errors/appError";
import { ServerReportData } from "./serverReport.slice";

export const selectLoading: (state: RootState) => string = (store) =>
    store.serverReport.loading;

export const selectData: (state: RootState) => ServerReportData | null = (
    store
) => store.serverReport.data;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.serverReport.error;
