import { DateTime, Duration } from "luxon";
import AppError from "../../shared/errors/appError";
import { RootState } from "../../shared/store";
import { getDuration } from "../../shared/utils/timeUtils";
import {
    GeneralData,
    HardwareData,
    CommunityData,
    ContactData,
    AdminAvailabilityData
} from "../servers/server.slice";

export const selectGeneral: (state: RootState) => GeneralData | null = (
    store
) => store.licenseApplication.general;

export const selectHardware: (state: RootState) => HardwareData | null = (
    store
) => store.licenseApplication.hardware;

export const selectCommunity: (state: RootState) => CommunityData | null = (
    store
) => store.licenseApplication.community;

export interface DateTimeAvailability {
    startTime: DateTime;
    endTime?: DateTime;
    duration: Duration;
    weekends: boolean;
    weekdays: boolean;
}

export const selectAvailablility: (
    state: RootState
) => DateTimeAvailability[] = (store) => {
    const result: DateTimeAvailability[] = [];
    store.licenseApplication.adminAvailability.forEach(
        (item: AdminAvailabilityData) => {
            const { weekends, weekdays } = item;
            const startTime = DateTime.fromISO(item.startTime);
            const endTime = DateTime.fromISO(item.endTime || "");
            const duration = getDuration(startTime, endTime);
            result.push({
                startTime,
                endTime,
                duration,
                weekends,
                weekdays
            });
        }
    );
    return result;
};

export const selectPrimaryContact: (state: RootState) => ContactData | null = (
    store
) => store.licenseApplication.primaryContact;

export const selectAdditionalContacts: (state: RootState) => ContactData[] = (
    store
) => store.licenseApplication.additionalContacts;

export const selectLoading: (state: RootState) => string = (store) =>
    store.licenseApplication.loading;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.licenseApplication.error;
