import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import store from "./shared/store";
import { selectIsLoggedIn } from "./features/login/login.selectors";
import Layout from "./features/layout/Layout";
import LoginPage from "./pages/LoginPage";
import ServersListPage from "./pages/ServersListPage";
import ServerProvidersListPage from "./pages/ServerProvidersListPage";
import GamesPage from "./pages/GamesListPage";
import GameDetailPage from "./pages/GameDetailPage";
import ServerProviderDetailPage from "./pages/ServerProviderDetailPage";
import ApplicationPage from "./pages/ApplicationPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import ServerDetailPage, { tabs } from "./pages/ServerDetailPage";

const App: React.FC = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const getProtectedRoutes = () => (
        <>
            <Route path="/admin/licenses" element={<ServersListPage />} />
            <Route path="/admin/licenses/:id" element={<ServerDetailPage />} />
            <Route
                path="/admin/licenses/:id/edit"
                element={<ServerDetailPage currentTab={tabs.edit} />}
            />
            <Route
                path="/admin/providers"
                element={<ServerProvidersListPage />}
            />
            <Route
                path="/admin/providers/:id"
                element={<ServerProviderDetailPage />}
            />
            <Route path="/admin/games" element={<GamesPage />} />
            <Route path="/admin/games/:id" element={<GameDetailPage />} />
            <Route
                path="*"
                element={<Navigate to="/admin/licenses" replace />}
            />
        </>
    );

    return (
        <Layout>
            <Routes>
                <Route path="/" element={<ApplicationPage />} />
                <Route path="/login" element={<LoginPage />} />
                {isLoggedIn ? (
                    getProtectedRoutes()
                ) : (
                    <>
                        <Route
                            path="/admin"
                            element={<Navigate to="/login" replace />}
                        />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </>
                )}
            </Routes>
        </Layout>
    );
};

const Meta: React.FC = () => {
    const { t: translate } = useTranslation();
    return (
        <Helmet>
            <title>{translate("meta.title")}</title>
            <meta name="description" content={translate("meta.description")} />
            <meta name="author" content={translate("meta.author")} />
            <meta name="copyright" content={translate("meta.copyright")} />
        </Helmet>
    );
};

const AppWrapper: React.FC = () => {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <Meta />
                <App />
            </HelmetProvider>
        </Provider>
    );
};

export default AppWrapper;
