import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";
import { AppDispatch } from "../../shared/store";
import { LoginValues, loginUser } from "./login.slice";
import { selectLoading } from "./login.selectors";
import { httpStatus } from "../../shared/api/api";

const Login: React.FC<{ redirect?: string }> = ({ redirect }) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const initialValues: LoginValues = {
        username: "", // username prop is labeled as email in the form
        password: ""
    };

    const schema = Yup.object().shape({
        username: Yup.string().required(translate("login.usernameRequired")),
        password: Yup.string().required(translate("login.passwordRequired"))
    });

    const loginHandler = async (values: LoginValues) => {
        const resultAction = await dispatch(loginUser(values));
        if (loginUser.fulfilled.match(resultAction)) {
            if (resultAction.payload && redirect) {
                navigate(redirect);
            }
        }
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={loginHandler}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-2" controlId="login.emailLabel">
                        <Form.Label>{translate("login.emailLabel")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            isInvalid={!!touched.username && !!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="login.passwordLabel"
                    >
                        <Form.Label>
                            {translate("login.passwordLabel")}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            isInvalid={!!touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        disabled={loading === httpStatus.pending || !isValid}
                    >
                        {loading === httpStatus.pending
                            ? translate("common.loading")
                            : translate("login.button")}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default Login;
