import { RootState } from "../../shared/store";
import { GameData } from "./game.slice";
import AppError from "../../shared/errors/appError";

export const selectLoading: (state: RootState) => string = (store) =>
    store.game.loading;

export const selectSingle: (state: RootState) => GameData | null = (store) =>
    store.game.single;

export const selectList: (state: RootState) => GameData[] | null = (store) =>
    store.game.list;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.game.error;
