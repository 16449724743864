import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoading, selectError } from "../features/games/game.selectors";
import GamesList from "../features/games/GamesList";
import { httpStatus } from "../shared/api/api";
import { newGameId } from "../features/games/game.slice";
import LoadingSpinner from "../shared/components/LoadingSpinner";
import ErrorAlert from "../shared/components/ErrorAlert";
import Title from "../shared/components/Title";

const GamesPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const addNewHandler = () => navigate(`/admin/games/${newGameId}`);

    const getAddNewButton = (classes = "") => (
        <Button
            variant="primary"
            className={classes}
            onClick={() => addNewHandler()}
        >
            {translate("common.addNew")}
        </Button>
    );

    const getTitle = () => (
        <div className="mb-1">
            <div className="d-flex align-items-center">
                <Title title={translate("games.title")} classes="me-2" />
                {loading === httpStatus.pending && <LoadingSpinner />}
                <div
                    className="ms-auto d-lg-block d-none"
                    data-testid="desktop-add-provider"
                >
                    {getAddNewButton()}
                </div>
            </div>
            <div
                className="d-md-block d-lg-none"
                data-testid="mobile-add-provider"
            >
                {getAddNewButton("btn-block")}
            </div>
        </div>
    );

    return (
        <Container>
            <div className="d-flex flex-column w-100">
                {getTitle()}
                <ErrorAlert
                    heading={translate("games.error")}
                    isShown={!!error}
                    details={error}
                />
                <GamesList />
            </div>
        </Container>
    );
};

export default GamesPage;
