import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { httpStatus } from "../../shared/api/api";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { selectData, selectLoading } from "./buildVersion.selectors";
import { getShortBuildVersion, getVersion } from "./buildVersion.slice";

const BuildVersion: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectData);
    const loading = useAppSelector(selectLoading);

    useEffect(() => {
        dispatch(getVersion());
    }, [dispatch]);

    return (
        <span>
            {loading === httpStatus.pending && translate("common.loading")}
            {loading === httpStatus.fulfilled && getShortBuildVersion(data)}
            {loading === httpStatus.rejected &&
                translate("version.errorClientVersion")}
        </span>
    );
};

export default BuildVersion;
