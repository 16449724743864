import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { FieldArray, Formik, Field, FieldMetaProps } from "formik";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { setAdminAvailability } from "./licenseApplication.slice";
import {
    getDuration,
    getHoursList,
    getStartOfToday,
    getTranslatedTimeZone
} from "../../shared/utils/timeUtils";
import {
    chevronLeftIcon,
    chevronRightIcon,
    minusIcon,
    plusIcon
} from "../../shared/constants/unicodeIcons";
import { AppDispatch } from "../../shared/store";
import { FormStep } from "../../pages/ApplicationPage";
import DetectCompletedForm from "../../shared/components/DetectCompletedForm";
import { AdminAvailabilityData } from "../servers/server.slice";

export interface AvailabilityFormData {
    isAlwaysAvailable: boolean;
    times: AdminAvailabilityData[];
}

const AdminAvailabilityForm: React.FC<FormStep> = ({
    completedHandler,
    nextStepHandler,
    backStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const maxTimeSlots = 3;
    const timeZone = getTranslatedTimeZone();
    const getHours = () => {
        const list = [
            <option key="..." value="">
                {translate("common.choose")}
            </option>
        ];
        getHoursList().forEach((dateTime) => {
            const iso = dateTime.toISO().toString();
            list.push(
                <option key={iso} value={iso}>
                    {dateTime.toLocaleString(DateTime.TIME_SIMPLE)}
                </option>
            );
        });
        return list;
    };

    const getDurationHours = (start: string, end: string) => {
        let hours = 0;
        let isOvernight = false;
        if (start && end) {
            const startTime = DateTime.fromISO(start);
            const endTime = DateTime.fromISO(end);
            hours = Math.floor(getDuration(startTime, endTime).as("hours"));
            isOvernight = startTime > endTime;
        }
        return (
            <span>
                {isOvernight
                    ? translate("adminAvailabilityForm.durationOvernight", {
                          hours
                      })
                    : translate("adminAvailabilityForm.duration", {
                          hours
                      })}
            </span>
        );
    };

    const schema = Yup.object().shape({
        isAlwaysAvailable: Yup.boolean(),
        times: Yup.mixed().when(["isAlwaysAvailable"], {
            is: (value: boolean) => !value,
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        startTime: Yup.string().required(
                            translate("adminAvailabilityForm.startTimeRequired")
                        ),
                        endTime: Yup.string().required(
                            translate("adminAvailabilityForm.endTimeRequired")
                        ),
                        weekends: Yup.boolean(),
                        weekdays: Yup.boolean()
                    })
                )
                .test("one-true", "required", (valuesList) => {
                    if (!valuesList || !valuesList.length) {
                        return true;
                    }
                    const errors = Array<Yup.ValidationError>();
                    valuesList.forEach((timeSlot, index) => {
                        if (!timeSlot.weekdays && !timeSlot.weekends) {
                            errors.push(
                                new Yup.ValidationError(
                                    translate(
                                        "adminAvailabilityForm.partOfWeekRequired"
                                    ),
                                    null,
                                    `times.${index}.weekends`
                                )
                            );
                            errors.push(
                                new Yup.ValidationError(
                                    translate(
                                        "adminAvailabilityForm.partOfWeekRequired"
                                    ),
                                    null,
                                    `times.${index}.weekdays`
                                )
                            );
                        }
                    });
                    return errors.length
                        ? new Yup.ValidationError(errors)
                        : true;
                })
        })
    });

    const initialValues: AvailabilityFormData = {
        isAlwaysAvailable: false,
        times: [
            {
                startTime: "",
                endTime: "",
                duration: 0,
                weekdays: true,
                weekends: true
            }
        ]
    };

    const submitHandler = (values: AvailabilityFormData) => {
        if (values.isAlwaysAvailable) {
            const alwaysAvailable: AdminAvailabilityData = {
                startTime: getStartOfToday().toISO().toString(),
                endTime: getStartOfToday().toISO().toString(),
                duration: 24,
                weekends: true,
                weekdays: true
            };
            dispatch(setAdminAvailability([alwaysAvailable]));
        } else {
            const customHours: AdminAvailabilityData[] = values.times.map(
                (item) => {
                    return {
                        startTime: item.startTime,
                        endTime: item.endTime,
                        duration: Math.floor(
                            getDuration(
                                DateTime.fromISO(item.startTime),
                                DateTime.fromISO(item?.endTime || "")
                            ).as("hours")
                        ),
                        weekends: item.weekends,
                        weekdays: item.weekdays
                    };
                }
            );
            dispatch(setAdminAvailability(customHours));
        }

        if (nextStepHandler) {
            nextStepHandler();
        }
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <DetectCompletedForm completedHandler={completedHandler} />
                    <p>{translate("adminAvailabilityForm.info")}</p>
                    <>
                        <Card>
                            <Card.Body>
                                <Form.Check
                                    label={translate(
                                        "adminAvailabilityForm.isAlwaysAvailable"
                                    )}
                                    name="isAlwaysAvailable"
                                    type="checkbox"
                                    id="isAlwaysAvailable"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultChecked={values.isAlwaysAvailable}
                                />
                            </Card.Body>
                        </Card>
                        <p className="my-2">
                            {translate("adminAvailabilityForm.or")}
                        </p>
                        <FieldArray
                            name="times"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.times.map((item, index) => (
                                        <Card
                                            className="mb-2"
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                        >
                                            <Row className="mb-2 d-flex card-body pb-1">
                                                <Form.Group
                                                    as={Col}
                                                    lg
                                                    controlId={`AdminAvailabilityForm.startTime.${index}`}
                                                >
                                                    <Form.Label>
                                                        {translate(
                                                            "adminAvailabilityForm.startTime"
                                                        )}{" "}
                                                        {timeZone &&
                                                            translate(
                                                                "common.parentheses",
                                                                {
                                                                    value: timeZone
                                                                }
                                                            )}
                                                    </Form.Label>
                                                    <Field
                                                        name={`times.${index}.startTime`}
                                                    >
                                                        {({
                                                            meta
                                                        }: {
                                                            meta: FieldMetaProps<AdminAvailabilityData>;
                                                        }) => (
                                                            <>
                                                                <Form.Control
                                                                    aria-label={`admin-${
                                                                        index +
                                                                        1
                                                                    }-start-time`}
                                                                    as="select"
                                                                    name={`times.${index}.startTime`}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        item.startTime
                                                                    }
                                                                    isInvalid={
                                                                        !!meta.touched &&
                                                                        !!meta.error
                                                                    }
                                                                    disabled={
                                                                        values.isAlwaysAvailable
                                                                    }
                                                                >
                                                                    {getHours()}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {meta.error}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <Form.Group
                                                    as={Col}
                                                    lg
                                                    controlId={`AdminAvailabilityForm.endTime.${index}`}
                                                >
                                                    <Form.Label>
                                                        {translate(
                                                            "adminAvailabilityForm.endTime"
                                                        )}{" "}
                                                        {getDurationHours(
                                                            values?.times[index]
                                                                ?.startTime ||
                                                                "",
                                                            values?.times[index]
                                                                ?.endTime || ""
                                                        )}
                                                    </Form.Label>
                                                    <Field
                                                        name={`times.${index}.endTime`}
                                                    >
                                                        {({
                                                            meta
                                                        }: {
                                                            meta: FieldMetaProps<AdminAvailabilityData>;
                                                        }) => (
                                                            <>
                                                                <Form.Control
                                                                    aria-label={`admin-${
                                                                        index +
                                                                        1
                                                                    }-end-time`}
                                                                    as="select"
                                                                    name={`times.${index}.endTime`}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        item.endTime
                                                                    }
                                                                    isInvalid={
                                                                        !!meta.touched &&
                                                                        !!meta.error
                                                                    }
                                                                    disabled={
                                                                        values.isAlwaysAvailable
                                                                    }
                                                                >
                                                                    {getHours()}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {meta.error}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <Form.Group
                                                    as={Col}
                                                    md
                                                    className="mb-0 mt-1"
                                                    controlId="LicenseAdminAvailabilityForm.partOfWeek"
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div className="mt-0 mt-md-4">
                                                            <Field
                                                                name={`times.${index}.weekdays`}
                                                            >
                                                                {({
                                                                    meta
                                                                }: {
                                                                    meta: FieldMetaProps<AdminAvailabilityData>;
                                                                }) => (
                                                                    <Form.Check
                                                                        aria-label={`admin-${
                                                                            index +
                                                                            1
                                                                        }-weekdays`}
                                                                        type="switch"
                                                                        role="switch"
                                                                        label={translate(
                                                                            "adminAvailabilityForm.weekdays"
                                                                        )}
                                                                        id={`times.${index}.weekdays`}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            setFieldValue(
                                                                                `times.${index}.weekdays`,
                                                                                event
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        checked={
                                                                            item.weekdays
                                                                        }
                                                                        isInvalid={
                                                                            !!meta.error
                                                                        }
                                                                        disabled={
                                                                            values.isAlwaysAvailable
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                            <Field
                                                                name={`times.${index}.weekends`}
                                                            >
                                                                {({
                                                                    meta
                                                                }: {
                                                                    // eslint-disable-next-line react/no-unused-prop-types
                                                                    meta: FieldMetaProps<AdminAvailabilityData>;
                                                                }) => (
                                                                    <>
                                                                        <Form.Check
                                                                            aria-label={`admin-${
                                                                                index +
                                                                                1
                                                                            }-weekends`}
                                                                            type="switch"
                                                                            role="switch"
                                                                            label={translate(
                                                                                "adminAvailabilityForm.weekends"
                                                                            )}
                                                                            id={`times.${index}.weekends`}
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                setFieldValue(
                                                                                    `times.${index}.weekends`,
                                                                                    event
                                                                                        .target
                                                                                        .checked
                                                                                )
                                                                            }
                                                                            onBlur={
                                                                                handleBlur
                                                                            }
                                                                            checked={
                                                                                item.weekends
                                                                            }
                                                                            isInvalid={
                                                                                !!meta.error
                                                                            }
                                                                            disabled={
                                                                                values.isAlwaysAvailable
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback
                                                                            type="invalid"
                                                                            className={
                                                                                meta.error
                                                                                    ? "d-block"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <Button
                                                            variant="danger"
                                                            className={`align-self-end ${
                                                                index === 0
                                                                    ? "invisible"
                                                                    : ""
                                                            }`}
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }
                                                            disabled={
                                                                values.isAlwaysAvailable ||
                                                                index === 0
                                                            }
                                                        >
                                                            {minusIcon}{" "}
                                                            {translate(
                                                                "adminAvailabilityForm.remove"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                        </Card>
                                    ))}
                                    {values.times.length < maxTimeSlots && (
                                        <Button
                                            variant="success"
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.push(
                                                    initialValues.times[0]
                                                )
                                            }
                                            disabled={values.isAlwaysAvailable}
                                        >
                                            {plusIcon}{" "}
                                            {translate(
                                                "adminAvailabilityForm.add"
                                            )}
                                        </Button>
                                    )}
                                </div>
                            )}
                        />
                    </>
                    <div className="d-flex mt-1">
                        <Button
                            variant="secondary"
                            role="button"
                            type="button"
                            onClick={backStepHandler}
                        >
                            {chevronLeftIcon} {translate("common.back")}
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-auto"
                            role="button"
                            type="submit"
                        >
                            {translate("common.next")} {chevronRightIcon}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AdminAvailabilityForm;
