import { useTranslation } from "react-i18next";
import "../../translations/i18n";

const KeyValue: React.FC<{
    value?: string | number;
    keyName?: string;
    valueClassnames?: string;
}> = ({ keyName = "", value = "", valueClassnames = "" }) => {
    const { t: translate } = useTranslation();

    return (
        <div className="mb-2">
            <span className="text-muted">{keyName}</span>
            <br />
            <span className={valueClassnames}>
                {value || `${translate("common.notApplicable")}`}
            </span>
        </div>
    );
};

export default KeyValue;
