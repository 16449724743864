import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { RootState } from "../../shared/store";
import { getDuration } from "../../shared/utils/timeUtils";
import { apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";
import {
    GeneralData,
    HardwareData,
    CommunityData,
    AdminAvailabilityData,
    ContactData
} from "../servers/server.slice";

const namespace = "licenseApplication";

export interface ApplyData {
    gameId: string;
    primaryContact: ContactData;
    additionalContacts?: ContactData[];
    serverDetails: {
        ipAddress: string;
        locales: string[];
        name: string;
        playerSlots: number;
        serverProviderId: string;
        additionalComments?: string;
        adminAvailability: AdminAvailabilityData[];
        availableAdmins: number;
        community: CommunityData;
        hardwareProfile?: HardwareData;
    };
}

export interface LicenseApplication {
    loading: string;
    error: AppError | null;
    general: GeneralData | null;
    hardware: HardwareData | null;
    community: CommunityData | null;
    adminAvailability: AdminAvailabilityData[];
    primaryContact: ContactData | null;
    additionalContacts: ContactData[];
    unsavedChangesWarning?: boolean;
}

const initialState: LicenseApplication = {
    loading: httpStatus.idle,
    general: null,
    hardware: null,
    community: null,
    adminAvailability: [],
    primaryContact: null,
    additionalContacts: [],
    unsavedChangesWarning: false,
    error: null
};

export const getApplicationBody: (
    application: LicenseApplication
) => ApplyData = (application) => {
    const general = { ...application.general };
    const hardware = { ...application.hardware };
    const primaryContact = { ...application.primaryContact };
    const additionalContacts = [...application.additionalContacts];
    const community = { ...application.community };
    const adminAvailability = [...application.adminAvailability];

    const result: ApplyData = {
        gameId: general.gameId || "",
        primaryContact: {
            name: primaryContact.name || "",
            country: primaryContact.country || "",
            email: primaryContact.email || "",
            ...(primaryContact.forumName
                ? { forumName: primaryContact.forumName }
                : {}),
            discordName: primaryContact.discordName || "",
            steamId: primaryContact.steamId || ""
        },
        ...(additionalContacts && additionalContacts.length > 0
            ? {
                  additionalContacts: [
                      ...additionalContacts.map((item: ContactData) => {
                          return {
                              name: item.name || "",
                              country: item.country || "",
                              email: item.email || "",
                              discordName: item.discordName || "",
                              steamId: item.steamId || ""
                          };
                      })
                  ]
              }
            : {}),
        serverDetails: {
            name: general.name || "",
            playerSlots: general.playerSlots || 0,
            serverProviderId: general.serverProviderId || "",
            ipAddress: general.ipAddress || "",
            locales:
                general.locales && general.locales.length > 0
                    ? general.locales
                    : [],
            ...(general.additionalComments
                ? { additionalComments: general.additionalComments }
                : {}),
            availableAdmins: general.availableAdmins || 0,
            community: {
                name: community.name || "",
                ...(community.size ? { size: community.size } : {}),
                ...(community.tag ? { tag: community.tag } : {}),
                url: community.url || ""
            },
            ...(general?.hardwareProfileRequired
                ? {
                      hardwareProfile: {
                          operatingSystem: hardware.operatingSystem || "",
                          cpuModel: hardware.cpuModel || "",
                          cpuFrequency: hardware.cpuFrequency || "",
                          physicalMemory: hardware.physicalMemory || "",
                          diskDrives: hardware.diskDrives || "",
                          networkSpeed: hardware.networkSpeed || "",
                          location: hardware.location || "",
                          ...(hardware.hostingCompany
                              ? { hostingCompany: hardware.hostingCompany }
                              : {})
                      }
                  }
                : {}),
            adminAvailability:
                adminAvailability && adminAvailability.length > 0
                    ? [
                          ...adminAvailability.map(
                              (item: AdminAvailabilityData) => {
                                  return {
                                      startTime: DateTime.fromISO(
                                          item.startTime
                                      )
                                          .toUTC()
                                          .toFormat("HH:00"),
                                      duration: Math.floor(
                                          getDuration(
                                              DateTime.fromISO(
                                                  item.startTime || ""
                                              ),
                                              DateTime.fromISO(
                                                  item.endTime || ""
                                              )
                                          ).as("hours")
                                      ),
                                      weekdays: item.weekdays,
                                      weekends: item.weekends
                                  };
                              }
                          )
                      ]
                    : []
        }
    };
    return result;
};

export const apply = createAsyncThunk<
    ApplyData,
    void,
    { rejectValue: AppError; state: RootState }
>(`${namespace}/apply`, async (_, { getState, rejectWithValue }) => {
    const { licenseApplication } = getState();
    const payload = getApplicationBody(licenseApplication);
    try {
        return await apiNoAuth.post<ApplyData, ApplyData>(
            endpoints.server.apply,
            payload
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const unsavedChangesWarningHandler = (event: BeforeUnloadEvent) => {
    const unloadEvent = event;
    unloadEvent.preventDefault();
    unloadEvent.returnValue = true;
};

const licenseApplicationSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setGeneral(state, { payload }: { payload: GeneralData }) {
            state.general = payload;
        },
        setHardware(state, { payload }: { payload: HardwareData }) {
            state.hardware = payload;
        },
        setCommunity(state, { payload }: { payload: CommunityData }) {
            state.community = payload;
        },
        setAdminAvailability(
            state,
            { payload }: { payload: AdminAvailabilityData[] }
        ) {
            state.adminAvailability = payload;
        },
        setPrimaryContact(state, { payload }: { payload: ContactData }) {
            state.primaryContact = payload;
        },
        setAdditionalContacts(state, { payload }: { payload: ContactData[] }) {
            state.additionalContacts = payload;
        },
        setUnsavedChangesWarning(state, { payload }: { payload: boolean }) {
            if (!state.unsavedChangesWarning && payload) {
                window.addEventListener(
                    "beforeunload",
                    unsavedChangesWarningHandler
                );
            } else if (state.unsavedChangesWarning && !payload) {
                window.removeEventListener(
                    "beforeunload",
                    unsavedChangesWarningHandler
                );
            }
            state.unsavedChangesWarning = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(apply.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(apply.fulfilled, (state) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
        });
        builder.addCase(apply.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export const {
    setGeneral,
    setHardware,
    setCommunity,
    setAdminAvailability,
    setPrimaryContact,
    setAdditionalContacts,
    setUnsavedChangesWarning
} = licenseApplicationSlice.actions;

export default licenseApplicationSlice.reducer;
