import { DateTime, Duration } from "luxon";

export const getStartOfToday: () => DateTime = () => {
    return DateTime.now().startOf("day");
};

export const getHoursList: () => DateTime[] = () => {
    const result = Array(24)
        .fill(null)
        .map((_, index) => getStartOfToday().plus({ hour: index }));
    return result;
};

export const getTranslatedTimeZone: () => string = () => {
    return DateTime.now().offsetNameShort;
};

export const getDuration: (start?: DateTime, end?: DateTime) => Duration = (
    start,
    end
) => {
    if (!start || !end) {
        const time = DateTime.fromObject({
            millisecond: 0
        });
        return time.diff(time);
    }
    return (start >= end ? end.plus({ day: 1 }) : end).diff(start);
};
