import { RootState } from "../../shared/store";
import { ServerProviderData } from "./serverProvider.slice";
import AppError from "../../shared/errors/appError";

export const selectLoading: (state: RootState) => string = (store) =>
    store.serverProvider.loading;

export const selectSingle: (state: RootState) => ServerProviderData | null = (
    store
) => store.serverProvider.single;

export const selectList: (state: RootState) => ServerProviderData[] | null = (
    store
) => store.serverProvider.list;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.serverProvider.error;
