import { RootState } from "../../shared/store";
import AppError from "../../shared/errors/appError";

export const selectLoading: (store: RootState) => string = (store) =>
    store.login.loading;

export const selectIsLoggedIn: (store: RootState) => boolean = (store) =>
    store.login.isLoggedIn;

export const selectPromptLogin: (store: RootState) => boolean = (store) =>
    store.login.promptLogin;

export const selectError: (store: RootState) => AppError | null = (store) =>
    store.login.error;
