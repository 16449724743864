import { Card, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import LoginForm from "../features/login/LoginForm";
import { selectError } from "../features/login/login.selectors";
import ApiVersion from "../features/apiVersion/ApiVersion";
import { useAppSelector } from "../shared/hooks";
import { selectLoading as selectClientLoading } from "../features/buildVersion/buildVersion.selectors";
import { httpStatus } from "../shared/api/api";

const LoginPage: React.FC = () => {
    const { t: translate } = useTranslation();
    const error = useAppSelector(selectError);
    const versionLoading = useAppSelector(selectClientLoading);

    return (
        <Card className="shadow-sm">
            <Card.Img
                variant="top"
                src="logo.png"
                alt="logo"
                className="pt-2"
            />
            <Card.Body>
                <Card.Title className="text-muted">
                    {translate("login.title")}
                </Card.Title>
                {error && (
                    <Alert variant="danger">
                        {translate("login.unsuccessfulLogin", {
                            code: error.code
                        })}
                    </Alert>
                )}
                <LoginForm redirect="/admin/licenses" />
                <small className="w-100 mt-2 text-muted d-flex justify-content-end align-items-baseline">
                    {translate("version.version")}{" "}
                    <ApiVersion
                        classNames={
                            versionLoading === httpStatus.rejected
                                ? "text-danger"
                                : "text-primary"
                        }
                    />
                </small>
            </Card.Body>
        </Card>
    );
};

export default LoginPage;
