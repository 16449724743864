// https://stackoverflow.com/a/28357857
export interface Language {
    [id: string]: string;
}
const languages: Language = {
    af: "Afrikaans",
    ak: "Akan",
    sq: "Albanian",
    am: "Amharic",
    ar: "Arabic",
    hy: "Armenian",
    as: "Assamese",
    asa: "Asu",
    az: "Azerbaijani",
    bm: "Bambara",
    eu: "Basque",
    be: "Belarusian",
    bem: "Bemba",
    bez: "Bena",
    bn: "Bengali",
    bs: "Bosnian",
    bg: "Bulgarian",
    my: "Burmese",
    ca: "Catalan",
    tzm: "Central Morocco Tamazight",
    chr: "Cherokee",
    cgg: "Chiga",
    zh: "Chinese",
    kw: "Cornish",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    ebu: "Embu",
    en: "English",
    eo: "Esperanto",
    et: "Estonian",
    ee: "Ewe",
    fo: "Faroese",
    fil: "Filipino",
    fi: "Finnish",
    fr: "French",
    ff: "Fulah",
    gl: "Galician",
    lg: "Ganda",
    ka: "Georgian",
    de: "German",
    el: "Greek",
    gu: "Gujarati",
    guz: "Gusii",
    ha: "Hausa",
    haw: "Hawaiian",
    he: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    is: "Icelandic",
    ig: "Igbo",
    id: "Indonesian",
    ga: "Irish",
    it: "Italian",
    ja: "Japanese",
    kea: "Kabuverdianu",
    kab: "Kabyle",
    kl: "Kalaallisut",
    kln: "Kalenjin",
    kam: "Kamba",
    kn: "Kannada",
    kk: "Kazakh",
    km: "Khmer",
    ki: "Kikuyu",
    rw: "Kinyarwanda",
    kok: "Konkani",
    ko: "Korean",
    khq: "Koyra Chiini",
    ses: "Koyraboro Senni",
    lag: "Langi",
    lv: "Latvian",
    lt: "Lithuanian",
    luo: "Luo",
    luy: "Luyia",
    mk: "Macedonian",
    jmc: "Machame",
    kde: "Makonde",
    mg: "Malagasy",
    ms: "Malay",
    ml: "Malayalam",
    mt: "Maltese",
    gv: "Manx",
    mr: "Marathi",
    mas: "Masai",
    mer: "Meru",
    mfe: "Morisyen",
    naq: "Nama",
    ne: "Nepali",
    nd: "North Ndebele",
    nb: "Norwegian Bokmål",
    nn: "Norwegian Nynorsk",
    nyn: "Nyankole",
    or: "Oriya",
    om: "Oromo",
    ps: "Pashto",
    fa: "Persian",
    pl: "Polish",
    pt: "Portuguese",
    pa: "Punjabi",
    ro: "Romanian",
    rm: "Romansh",
    rof: "Rombo",
    ru: "Russian",
    rwk: "Rwa",
    saq: "Samburu",
    sg: "Sango",
    seh: "Sena",
    sr: "Serbian",
    sn: "Shona",
    ii: "Sichuan Yi",
    si: "Sinhala",
    sk: "Slovak",
    sl: "Slovenian",
    xog: "Soga",
    so: "Somali",
    es: "Spanish",
    sw: "Swahili",
    sv: "Swedish",
    gsw: "Swiss German",
    shi: "Tachelhit",
    dav: "Taita",
    ta: "Tamil",
    te: "Telugu",
    teo: "Teso",
    th: "Thai",
    bo: "Tibetan",
    ti: "Tigrinya",
    to: "Tonga",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    vun: "Vunjo",
    cy: "Welsh",
    yo: "Yoruba",
    zu: "Zulu"
} as const;

export default languages;
