import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsHeartFill, BsHeartHalf, BsHeart } from "react-icons/bs";
import {
    selectData,
    selectError,
    selectLoading
} from "./serverReport.selectors";
import { AppDispatch } from "../../shared/store";
import { getReport } from "./serverReport.slice";
import { httpStatus } from "../../shared/api/api";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorAlert from "../../shared/components/ErrorAlert";
import KeyValue from "../../shared/components/KeyValue";

const ServerReport: React.FC<{ id?: string }> = ({ id = "" }) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const data = useSelector(selectData);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const [showConfirmModal, setShowConfirModal] = useState(false);
    const showConfirmModalHandler = () => setShowConfirModal(true);
    const hideConfirmModalHandler = () => setShowConfirModal(false);

    useEffect(() => {
        if (id) {
            dispatch(getReport(id));
        }
    }, [dispatch, id]);

    const getHealthText = (nearFullHealthStatus?: number) => {
        switch (nearFullHealthStatus) {
            case 1: {
                return translate("serverReport.badHealth");
            }
            case 2: {
                return translate("serverReport.okHealth");
            }
            case 3: {
                return translate("serverReport.goodHealth");
            }
            default: {
                return translate("serverReport.unknownHealth");
            }
        }
    };

    const getButton = () => {
        let icon =
            loading === httpStatus.pending ? (
                <LoadingSpinner variant="light" />
            ) : (
                <BsHeart title={translate("serverReport.unknownHealth")} />
            );
        let variant = "secondary";
        if (
            loading === httpStatus.fulfilled &&
            data?.nearFullHealthStatus !== undefined
        ) {
            const health = data.nearFullHealthStatus;
            switch (health) {
                case 1: {
                    icon = <BsHeart title={getHealthText(health)} />;
                    variant = "danger";
                    break;
                }
                case 2: {
                    icon = <BsHeartHalf title={getHealthText(health)} />;
                    variant = "warning";
                    break;
                }
                case 3: {
                    icon = <BsHeartFill title={getHealthText(health)} />;
                    variant = "success";
                    break;
                }
                default: {
                    icon = <BsHeart title={getHealthText()} />;
                    variant = "secondary";
                }
            }
        }

        return (
            <Button variant={variant} onClick={showConfirmModalHandler}>
                <div className="d-flex align-items-center justify-content-center">
                    <span className="pe-1">{icon}</span>
                    <span className="text-capitalize">
                        {loading === httpStatus.pending &&
                            translate("serverReport.loading")}
                        {loading === httpStatus.fulfilled &&
                            (data?.serverAddress?.ip ||
                                translate("common.unknown"))}
                        {loading === httpStatus.rejected &&
                            translate("serverReport.error")}
                        {loading === httpStatus.idle &&
                            translate("common.unknown")}
                    </span>
                </div>
            </Button>
        );
    };

    const getReportDetails = () => (
        <div>
            {data && (
                <>
                    <Row className="d-flex justify-content-between">
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.tickrate")}
                                value={
                                    !data.nearFullHealthStatus
                                        ? ""
                                        : getHealthText(
                                              data.nearFullHealthStatus
                                          )
                                }
                            />
                        </Col>
                        <Col md>
                            <KeyValue
                                keyName={translate(
                                    "serverReport.reportTimestamp"
                                )}
                                value={
                                    data.timestamp &&
                                    DateTime.fromMillis(
                                        data.timestamp
                                    ).toLocaleString(DateTime.DATETIME_SHORT)
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between">
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.ipAddress")}
                                value={data.serverAddress.ip}
                            />
                        </Col>
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.serverPort")}
                                value={data.serverAddress.port}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.playerCount")}
                                value={data.playerCount || "0"}
                            />
                        </Col>
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.nearFull")}
                                value={
                                    data.nearFull
                                        ? translate<string>("serverReport.yes")
                                        : translate<string>("serverReport.no")
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between">
                        <Col md>
                            <KeyValue
                                keyName={translate("serverReport.gameVersion")}
                                value={data.gameVersion}
                            />
                        </Col>
                        <Col md>
                            <KeyValue
                                keyName={translate(
                                    "serverReport.passwordProtected"
                                )}
                                value={
                                    data.passwordProtected
                                        ? translate<string>("serverReport.yes")
                                        : translate<string>("serverReport.no")
                                }
                            />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );

    return (
        <>
            {getButton()}
            {showConfirmModal && (
                <Modal
                    show={showConfirmModal}
                    onHide={hideConfirmModalHandler}
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {translate("serverReport.serverReport")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading === httpStatus.pending && <LoadingSpinner />}
                        {loading === httpStatus.rejected && (
                            <ErrorAlert
                                heading={translate("serverReport.error")}
                                isShown={!!error}
                                details={error}
                            />
                        )}
                        {loading === httpStatus.fulfilled && !data && (
                            <div className="w-100 text-center">
                                <p>{translate("serverReport.noDataFound")}</p>
                            </div>
                        )}
                        {loading === httpStatus.fulfilled &&
                            data &&
                            getReportDetails()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={hideConfirmModalHandler}
                        >
                            {translate("common.close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ServerReport;
