import {
    AnyAction,
    combineReducers,
    configureStore,
    Reducer
} from "@reduxjs/toolkit";
import loginReducer, { namespace } from "../features/login/login.slice";
import gameReducer from "../features/games/game.slice";
import serverProviderReducer from "../features/serverProviders/serverProvider.slice";
import serverSliceReducer from "../features/servers/server.slice";
import licenseApplicationSliceReducer from "../features/licenseApplication/licenseApplication.slice";
import licenseReducer from "../features/licenses/license.slice";
import serverReportReducer from "../features/serverReport/serverReport.slice";
import apiVersionSliceReducer from "../features/apiVersion/apiVersion.slice";
import buildVersionReducer from "../features/buildVersion/buildVersion.slice";

const appReducer = combineReducers({
    apiVersion: apiVersionSliceReducer,
    buildVersion: buildVersionReducer,
    licenseApplication: licenseApplicationSliceReducer,
    license: licenseReducer,
    login: loginReducer,
    game: gameReducer,
    serverReport: serverReportReducer,
    serverProvider: serverProviderReducer,
    server: serverSliceReducer
});

const rootReducer: Reducer = (
    state: RootState | undefined,
    action: AnyAction
) => {
    if (action.type === `${namespace}/resetStore`) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
