import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Col, Row, Card } from "react-bootstrap";
import KeyValue from "../../shared/components/KeyValue";
import { ContactData } from "./server.slice";

const ContactCard: React.FC<{ data: ContactData }> = ({ data }) => {
    const { t: translate } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    {translate(
                        `contactForm.${
                            data.isPrimary ? "primary" : "secondary"
                        }`
                    )}
                </Card.Title>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("contactForm.name")}
                            value={data.name}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("contactForm.email")}
                            value={data.email}
                        />
                    </Col>

                    <Col md>
                        <KeyValue
                            keyName={translate("contactForm.country")}
                            value={data.country}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("contactForm.discord")}
                            value={data.discordName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("contactForm.steamId")}
                            value={data.steamId}
                        />
                    </Col>
                    {data.forumName && (
                        <Col md={9}>
                            <KeyValue
                                keyName={translate("contactForm.forum")}
                                value={data.forumName}
                            />
                        </Col>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ContactCard;
