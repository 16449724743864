import { AuthToken } from "../utils/jwtUtils";

export const authTokenKey = "LSAuthToken";

const localAuthToken = {
    get: (): AuthToken | null => {
        const rawToken = localStorage.getItem(authTokenKey);
        if (rawToken) {
            return JSON.parse(rawToken) as AuthToken;
        }
        return null;
    },

    set: (authToken: AuthToken): void => {
        localStorage.setItem(authTokenKey, JSON.stringify(authToken));
    },

    clear: (): void => {
        localStorage.removeItem(authTokenKey);
    }
};

export default localAuthToken;
