import { RootState } from "../../shared/store";
import AppError from "../../shared/errors/appError";

export const selectLoading: (state: RootState) => string = (store) =>
    store.buildVersion.loading;

export const selectData: (state: RootState) => string | null = (store) =>
    store.buildVersion.data;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.buildVersion.error;
