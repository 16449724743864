import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import navLinks from "../../shared/constants/navLinks";

const MainNavigation: React.FC = () => {
    const { t: translate } = useTranslation();
    const activeClassNames = " fw-bold border-secondary";

    return (
        <div className="d-lg-block d-none bg-white shadow-sm">
            <div className="h-100 d-flex flex-column justify-content-between">
                <Nav className="flex-column pt-2 h-100">
                    {navLinks
                        .filter((link) => link.protected)
                        .map((link) => (
                            <NavLink
                                key={link.label}
                                className={({ isActive }) =>
                                    `nav-link text-muted  mb-1 border-end ${
                                        isActive ? activeClassNames : ""
                                    }`
                                }
                                to={link.route}
                            >
                                {translate(`navLinks.${link.label}`)}
                            </NavLink>
                        ))}
                </Nav>
            </div>
        </div>
    );
};

export default MainNavigation;
