import languages from "../constants/languages";

export const getDefaultLocale: () => string = () => {
    return "en";
};

export const getLocalesList: () => string[] = () => {
    return Object.keys(languages);
};

export const getLanguage: (localeCode: string) => string = (
    localeCode: string
) => {
    return languages[localeCode] || localeCode;
};
