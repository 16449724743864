import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    ListGroup,
    ListGroupItem,
    Table,
    Dropdown,
    Button
} from "react-bootstrap";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { selectList, selectSort } from "./server.selectors";
import { sort, sortProperty } from "./server.slice";
import { AppDispatch } from "../../shared/store";
import testIds from "../../shared/constants/testIds";
import style from "./SearchResults.module.scss";
import LicenseStatusChange from "../licenses/LicenseStatusChange";

const SearchResults: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const data = useSelector(selectList);
    const sortData = useSelector(selectSort);

    const sortHandler = (property: string) =>
        dispatch(
            sort({
                property,
                descending:
                    sortData.property === property
                        ? !sortData.descending
                        : false
            })
        );

    const getSortIcon = (property: string) => {
        if (sortData.property === property) {
            return sortData.descending ? <BsArrowDown /> : <BsArrowUp />;
        }
        return "";
    };

    const getDesktop = () => (
        <div>
            {data && data.length > 0 && (
                <Table
                    hover
                    className="bg-white border"
                    data-testid={testIds.searchResults.desktop}
                >
                    <thead className={style["heading-sort"]}>
                        <tr>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.licenseNumber)
                                    }
                                >
                                    {translate(
                                        "searchResults.licenseNumberHeader"
                                    )}{" "}
                                    {getSortIcon(sortProperty.licenseNumber)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.name)
                                    }
                                >
                                    {translate("searchResults.nameHeader")}{" "}
                                    {getSortIcon(sortProperty.name)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.game)
                                    }
                                >
                                    {translate("searchResults.gameHeader")}{" "}
                                    {getSortIcon(sortProperty.game)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.contactName)
                                    }
                                >
                                    {translate(
                                        "searchResults.contactNameHeader"
                                    )}{" "}
                                    {getSortIcon(sortProperty.contactName)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.contactEmail)
                                    }
                                >
                                    {translate(
                                        "searchResults.contactEmailHeader"
                                    )}{" "}
                                    {getSortIcon(sortProperty.contactEmail)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.communityName)
                                    }
                                >
                                    {translate(
                                        "searchResults.communityNameHeader"
                                    )}{" "}
                                    {getSortIcon(sortProperty.communityName)}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="link"
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        sortHandler(sortProperty.status)
                                    }
                                >
                                    {translate("searchResults.statusHeader")}{" "}
                                    {getSortIcon(sortProperty.status)}
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.length > 0 &&
                            data.map((item, index) => (
                                <tr key={item.id}>
                                    <td
                                        data-testid={`${testIds.searchResults.licenseNumber}${index}`}
                                    >
                                        <Link to={`/admin/licenses/${item.id}`}>
                                            {item.licenseNumber}
                                        </Link>
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.name}${index}`}
                                    >
                                        {item.name}
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.game}${index}`}
                                    >
                                        {item.game}
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.contactName}${index}`}
                                    >
                                        {item.contactName}
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.contactEmail}${index}`}
                                    >
                                        {item.contactEmail}
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.communityName}${index}`}
                                    >
                                        {item.communityName}
                                    </td>
                                    <td
                                        data-testid={`${testIds.searchResults.status}${index}`}
                                    >
                                        <LicenseStatusChange
                                            classNames="w-100"
                                            currentStatus={item.status}
                                            id={item.licenseId}
                                            name={item.name}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            )}
        </div>
    );

    const getMobileDetail = (value: string, translation: string) => (
        <div className="mb-2 text-truncate">
            <small className="text-muted">{translation}</small>
            <br />
            {value}
        </div>
    );

    const getMobileSort = () => (
        <Dropdown>
            <Dropdown.Toggle
                data-testid={testIds.searchResults.sortMobile}
                variant="secondary"
                className="w-100 d-flex align-items-center justify-content-center"
                id="select-sort-order"
                bsPrefix="no-arrow"
            >
                {translate("searchForm.sortBy")}{" "}
                {translate(`searchForm.${sortData.property}`)}{" "}
                {getSortIcon(sortData.property)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => sortHandler(sortProperty.licenseNumber)}
                >
                    {translate("searchForm.licenseNumber")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sortHandler(sortProperty.name)}>
                    {translate("searchForm.name")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sortHandler(sortProperty.game)}>
                    {translate("searchForm.game")}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => sortHandler(sortProperty.communityName)}
                >
                    {translate("searchForm.communityName")}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => sortHandler(sortProperty.contactEmail)}
                >
                    {translate("searchForm.contactEmail")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sortHandler(sortProperty.status)}>
                    {translate("searchForm.status")}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    const getMobile = () => (
        <ListGroup data-testid={testIds.searchResults.mobile}>
            {data &&
                data.map((item, index) => (
                    <ListGroupItem
                        key={item.id}
                        data-testid={`${testIds.searchResults.mobileValue}${index}`}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <Link to={`/admin/licenses/${item.id}`}>
                                {item.licenseNumber}
                            </Link>
                            <LicenseStatusChange
                                classNames="w-100"
                                currentStatus={item.status}
                                id={item.licenseId}
                                name={item.name}
                            />
                        </div>
                        <div className="mb-1 text-truncate">{item.name}</div>
                        {getMobileDetail(
                            item.ipAddress,
                            translate("generalForm.ipAddress")
                        )}
                        {getMobileDetail(
                            item.game,
                            translate("searchForm.game")
                        )}

                        {getMobileDetail(
                            item.contactName,
                            translate("searchForm.contactName")
                        )}
                        {getMobileDetail(
                            item.contactEmail,
                            translate("searchForm.contactEmail")
                        )}
                        {getMobileDetail(
                            item.communityName,
                            translate("searchForm.communityName")
                        )}
                    </ListGroupItem>
                ))}
        </ListGroup>
    );

    return (
        <div className={`d-flex flex-column ${style["truncate-table-text"]}`}>
            <div className="d-lg-none mb-2">{getMobileSort()}</div>
            <div className="d-lg-block d-none">{getDesktop()}</div>
            <div className="d-lg-none mb-2">{getMobile()}</div>
        </div>
    );
};

export default SearchResults;
