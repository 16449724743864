import { RootState } from "../../shared/store";
import AppError from "../../shared/errors/appError";
import {
    GeneralData,
    HardwareData,
    CommunityData,
    ContactData,
    CompressedSearchData,
    LicenseCardData,
    LicenseSort,
    ServerData,
    getDateTimeAvailability,
    SearchQuery
} from "./server.slice";
import { DateTimeAvailability } from "../licenseApplication/licenseApplication.selectors";

export const selectLoading: (state: RootState) => string = (store) =>
    store.server.loading;

export const selectSingle: (state: RootState) => ServerData | null = (store) =>
    store.server.single;

export const selectLicense: (state: RootState) => LicenseCardData | null = (
    store
) => store.server.license;

export const selectGeneralInfo: (state: RootState) => GeneralData | null = (
    store
) => store.server.generalInfo;

export const selectHardware: (state: RootState) => HardwareData | null = (
    store
) => store.server.hardwareProfile;

export const selectCommunity: (state: RootState) => CommunityData | null = (
    store
) => store.server.community;

export const selectAdminAvailability: (
    state: RootState
) => DateTimeAvailability[] = (store) => {
    return getDateTimeAvailability(store.server.adminAvailability);
};

export const selectPrimaryContact: (state: RootState) => ContactData | null = (
    store
) => store.server.primaryContact;

export const selectAdditionalContacts: (state: RootState) => ContactData[] = (
    store
) => store.server.additionalContacts;

export const selectList: (state: RootState) => CompressedSearchData[] | null = (
    store
) => store.server.list;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.server.error;

export const selectSort: (state: RootState) => LicenseSort = (store) =>
    store.server.sort;

export const selectSearchQuery: (state: RootState) => SearchQuery = (store) =>
    store.server.searchQuery;
