import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";
import ServerReport from "./ServerReport";

const namespace = "serverReport";

export interface ServerReportData {
    gameVersion: string;
    licenseId: string;
    nearFull: boolean;
    nearFullHealthStatus: number;
    passwordProtected: boolean;
    playerCount: number;
    timestamp: number;
    serverAddress: {
        ip: string;
        port: number;
    };
    serverName: string;
}

interface ServerReport {
    loading: string;
    data: ServerReportData | null;
    error: AppError | null;
}

const initialState: ServerReport = {
    loading: httpStatus.idle,
    data: null,
    error: null
};

export const getReport = createAsyncThunk<
    ServerReportData[],
    string,
    { rejectValue: AppError }
>(`${namespace}/getReport`, async (licenseId, { rejectWithValue }) => {
    try {
        return await api.get<ServerReportData[]>(
            endpoints.report.get(licenseId)
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

const serverReportSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReport.pending, (state) => {
            state.loading = httpStatus.pending;
            state.data = null;
            state.error = null;
        });
        builder.addCase(getReport.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            // Get most recent report
            const data = payload || [];
            if (data?.length) {
                state.data = data.reduce(
                    (current, result) =>
                        current.timestamp > result.timestamp ? current : result,
                    data[0]
                );
            } else {
                state.data = null;
            }
        });
        builder.addCase(getReport.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default serverReportSlice.reducer;
