import { Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../translations/i18n";
import { AppDispatch } from "../../shared/store";
import { getServer } from "./server.slice";
import {
    selectAdditionalContacts,
    selectAdminAvailability,
    selectCommunity,
    selectGeneralInfo,
    selectHardware,
    selectLicense,
    selectPrimaryContact,
    selectSingle,
    selectLoading as selectServerLoading
} from "./server.selectors";
import LicenseCard from "./LicenseCard";
import GeneralInfoCard from "./GeneralInfoCard";
import HardwareProfileCard from "./HardwareProfileCard";
import CommunityCard from "./CommunityCard";
import AdminAvailabilityCard from "./AdminAvailabilityCard";
import ContactCard from "./ContactCard";
import { selectLoading as selectLicenseLoading } from "../licenses/license.selectors";
import { httpStatus } from "../../shared/api/api";

const Server: React.FC<{ id?: string }> = ({ id }) => {
    const dispatch: AppDispatch = useDispatch();

    const server = useSelector(selectSingle);
    const license = useSelector(selectLicense);
    const generalInfo = useSelector(selectGeneralInfo);
    const hardware = useSelector(selectHardware);
    const community = useSelector(selectCommunity);
    const adminAvailability = useSelector(selectAdminAvailability);
    const primaryContact = useSelector(selectPrimaryContact);
    const additionalContacts = useSelector(selectAdditionalContacts);

    const licenseLoading = useSelector(selectLicenseLoading);
    const serverLoading = useSelector(selectServerLoading);

    useEffect(() => {
        if (id) {
            dispatch(getServer(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (licenseLoading === httpStatus.fulfilled && id) {
            dispatch(getServer(id));
        }
    }, [dispatch, id, licenseLoading]);

    return (
        <div className="card-grid-equal-height">
            {serverLoading !== httpStatus.pending && server && (
                <>
                    <Row>
                        <Col>{license && <LicenseCard data={license} />}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {generalInfo && (
                                <GeneralInfoCard data={generalInfo} />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg className="pe-lg-1">
                            {primaryContact && (
                                <ContactCard data={primaryContact} />
                            )}
                        </Col>
                        <Col lg className="mt-1 mt-lg-0 ps-lg-0">
                            {additionalContacts &&
                                additionalContacts.length > 0 &&
                                additionalContacts.map((item) => (
                                    <ContactCard key={item.email} data={item} />
                                ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg className="pe-lg-1">
                            {community && <CommunityCard data={community} />}
                        </Col>
                        <Col lg className="mt-1 mt-lg-0 ps-lg-0">
                            {adminAvailability &&
                                adminAvailability.length > 0 && (
                                    <AdminAvailabilityCard
                                        data={adminAvailability}
                                    />
                                )}
                        </Col>
                    </Row>
                    {hardware && (
                        <Row>
                            <Col>
                                <HardwareProfileCard data={hardware} />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default Server;
