import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, apiNoAuth, httpStatus } from "../../shared/api/api";
import endpoints from "../../shared/api/endpoints";
import AppError from "../../shared/errors/appError";

const namespace = "serverProvider";

export const newProviderId = "newProvider";

export interface UpdatedServerProvider {
    name: string;
    enabled: boolean;
    hardwareProfileRequired: boolean;
}

export interface ServerProviderData {
    name: string;
    id: string;
    enabled: boolean;
    hardwareProfileRequired: boolean;
}

export interface ServerProvider {
    loading: string;
    list: ServerProviderData[];
    single: ServerProviderData | null;
    error: AppError | null;
}

const initialState: ServerProvider = {
    loading: httpStatus.idle,
    single: null,
    list: [],
    error: null
};

export const newProviderPrep: ServerProviderData = {
    name: "",
    id: newProviderId,
    enabled: false,
    hardwareProfileRequired: false
};

export const getProvider = createAsyncThunk<
    ServerProviderData,
    string,
    { rejectValue: AppError }
>(`${namespace}/getProvider`, async (id, { rejectWithValue }) => {
    if (id === newProviderId) {
        return newProviderPrep;
    }
    try {
        return await apiNoAuth.get<ServerProviderData>(
            endpoints.serverProvider.get(id)
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export const getList = createAsyncThunk<
    ServerProviderData[],
    void,
    { rejectValue: AppError }
>(`${namespace}/getList`, async (_, { rejectWithValue }) => {
    try {
        return await apiNoAuth.get<ServerProviderData[]>(
            endpoints.serverProvider.get()
        );
    } catch (error) {
        return rejectWithValue(error as AppError);
    }
});

export const updateProvider = createAsyncThunk<
    ServerProviderData,
    ServerProviderData,
    { rejectValue: AppError }
>(
    `${namespace}/updateProvider`,
    async (values: ServerProviderData, { rejectWithValue }) => {
        try {
            const { id, ...valuesNoId } = values;
            if (id === newProviderId) {
                return await api.post<
                    UpdatedServerProvider,
                    ServerProviderData
                >(endpoints.serverProvider.post, valuesNoId);
            }
            return await api.put<UpdatedServerProvider, ServerProviderData>(
                endpoints.serverProvider.put(id),
                valuesNoId
            );
        } catch (error) {
            return rejectWithValue(error as AppError);
        }
    }
);

const sortByName = (a: ServerProviderData, b: ServerProviderData) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;

const serverProviderSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProvider.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(getProvider.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.single = payload as ServerProviderData;
            state.error = null;
        });
        builder.addCase(getProvider.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(getList.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.list = payload
                ? (payload.sort(sortByName) as ServerProviderData[])
                : [];
            state.error = null;
        });
        builder.addCase(getList.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });

        builder.addCase(updateProvider.pending, (state) => {
            state.loading = httpStatus.pending;
            state.error = null;
        });

        builder.addCase(updateProvider.fulfilled, (state, { payload }) => {
            state.loading = httpStatus.fulfilled;
            state.error = null;
            state.single = payload as ServerProviderData;
            const index = state.list?.findIndex(
                (provider) => payload.id === provider.id
            );
            if (index !== undefined && state.list) {
                state.list[index] = payload;
            }
            state.list.sort(sortByName);
        });
        builder.addCase(updateProvider.rejected, (state, { payload }) => {
            state.loading = httpStatus.rejected;
            state.error = payload as AppError;
        });
    }
});

export default serverProviderSlice.reducer;
