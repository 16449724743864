import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Formik } from "formik";
import * as Yup from "yup";
import { httpStatus } from "../../shared/api/api";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import {
    maxLengthLarge,
    maxLengthMedium,
    maxPlayerSlots,
    minPlayerSlots,
    steamIdLength
} from "../../shared/constants/validation";
import {
    selectCommunity,
    selectGeneralInfo,
    selectHardware,
    selectPrimaryContact,
    selectSingle,
    selectLoading as selectServerLoading
} from "./server.selectors";
import { ServerUpdateFormData, updateServer } from "./server.slice";
import { numbersPattern, urlOptionalHttp } from "../../shared/constants/regex";
import { requiredFieldIcon } from "../../shared/constants/unicodeIcons";

const ServerForm: React.FC = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const server = useAppSelector(selectSingle);
    const serverLoading = useAppSelector(selectServerLoading);
    const generalInfo = useAppSelector(selectGeneralInfo);
    const hardware = useAppSelector(selectHardware);
    const community = useAppSelector(selectCommunity);
    const primaryContact = useAppSelector(selectPrimaryContact);

    const initialValues: ServerUpdateFormData = {
        id: server?.id || "",
        userId: primaryContact?.id || "",
        serverName: generalInfo?.name || "",
        serverPlayerSlots: generalInfo?.playerSlots || 0,
        ...(server?.serverProvider.hardwareProfileRequired
            ? { serverHostingCompany: hardware?.hostingCompany }
            : {}),
        contactName: primaryContact?.name || "",
        nameAliasId: primaryContact?.nameAliasId || "",
        email: primaryContact?.email || "",
        emailAliasId: primaryContact?.emailAliasId || "",
        forumName: primaryContact?.forumName || "",
        forumAliasId: primaryContact?.forumAliasId || "",
        discordName: primaryContact?.discordName || "",
        discordAliasId: primaryContact?.discordAliasId || "",
        steamId: primaryContact?.steamId || "",
        steamAliasId: primaryContact?.steamAliasId || "",
        communityUrl: community?.url || ""
    };

    const schema = Yup.object().shape({
        serverName: Yup.string().required(
            translate("generalForm.serverNameRequired")
        ),
        serverPlayerSlots: Yup.number()
            .integer(translate("common.integerRequired"))
            .required(translate("generalForm.playerSlotsRequired"))
            .moreThan(
                minPlayerSlots - 1,
                translate("generalForm.playerSlotsUnder")
            )
            .lessThan(
                maxPlayerSlots + 1,
                translate("generalForm.playerSlotsOver")
            ),
        communityUrl: Yup.string()
            .required(translate("communityForm.urlRequired"))
            .matches(urlOptionalHttp, translate("communityForm.urlInvalid")),
        serverHostingCompany: Yup.string(),
        contactName: Yup.string().required(
            translate("contactForm.nameRequired")
        ),
        email: Yup.string()
            .required(translate("contactForm.emailRequired"))
            .email(translate("contactForm.emailInvalid")),
        discordName: Yup.string(),
        forumName: Yup.string(),
        steamId: Yup.string()
            .required(translate("contactForm.steamIdRequired"))
            .length(steamIdLength, translate("contactForm.steamIdLength"))
            .matches(numbersPattern, translate("contactForm.steamIdLetters"))
    });

    const submitHandler = async (values: ServerUpdateFormData) => {
        const resultAction = await dispatch(updateServer(values));
        if (updateServer.fulfilled.match(resultAction) && server) {
            navigate(`/admin/licenses/${server.id}`, { replace: true });
        }
    };

    const cancelHandler = () => {
        if (server) {
            navigate(`/admin/licenses/${server.id}`, { replace: true });
        }
    };

    const getForm = () => (
        <Card>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={submitHandler}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <h5>{translate("generalForm.title")}</h5>
                            <Row>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    controlId="serverForm.serverName"
                                >
                                    <Form.Label>
                                        {translate("generalForm.serverName")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-name-input"
                                        type="text"
                                        name="serverName"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.serverName}
                                        isInvalid={
                                            !!touched.serverName &&
                                            !!errors.serverName
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.serverName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="generalForm.playerSlots"
                                >
                                    <Form.Label>
                                        {translate("generalForm.playerSlots")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-player-slots-input"
                                        type="number"
                                        min="0"
                                        name="serverPlayerSlots"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.serverPlayerSlots}
                                        isInvalid={
                                            !!touched.serverPlayerSlots &&
                                            !!errors.serverPlayerSlots
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.serverPlayerSlots}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                {server?.serverProvider
                                    .hardwareProfileRequired && (
                                    <Form.Group
                                        className={`mb-2 ${
                                            !server?.serverProvider
                                                .hardwareProfileRequired &&
                                            "d-none"
                                        }`}
                                        as={Col}
                                        md={6}
                                        controlId="serverForm.serverHostingCompany"
                                    >
                                        <Form.Label>
                                            {translate(
                                                "hardwareForm.hostingCompany"
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            aria-label="server-hosting-company-input"
                                            type="text"
                                            name="serverHostingCompany"
                                            maxLength={maxLengthMedium}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                                !server?.serverProvider
                                                    .hardwareProfileRequired
                                            }
                                            value={values.serverHostingCompany}
                                            isInvalid={
                                                !!touched.serverHostingCompany &&
                                                !!errors.serverHostingCompany
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.serverHostingCompany}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Row>
                            <h5 className="mt-3">
                                {translate("contactForm.primary")}
                            </h5>
                            <Row>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.contactName"
                                >
                                    <Form.Label>
                                        {translate("contactForm.name")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-contact-name-input"
                                        type="text"
                                        name="contactName"
                                        maxLength={maxLengthMedium}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.contactName}
                                        isInvalid={
                                            !!touched.contactName &&
                                            !!errors.contactName
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.contactName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.email"
                                >
                                    <Form.Label>
                                        {translate("contactForm.email")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-contact-email-input"
                                        type="text"
                                        name="email"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        isInvalid={
                                            !!touched.email && !!errors.email
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.forumName"
                                >
                                    <Form.Label>
                                        {translate("contactForm.forum")}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-forum-name-input"
                                        type="text"
                                        name="forumName"
                                        maxLength={maxLengthMedium}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.forumName}
                                        isInvalid={
                                            !!touched.forumName &&
                                            !!errors.forumName
                                        }
                                        disabled={!primaryContact?.forumAliasId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.forumName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.discordName"
                                >
                                    <Form.Label>
                                        {translate("contactForm.discord")}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-discord-name-input"
                                        type="text"
                                        name="discordName"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.discordName}
                                        isInvalid={
                                            !!touched.discordName &&
                                            !!errors.discordName
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.discordName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.steamId"
                                >
                                    <Form.Label>
                                        {translate("contactForm.steamId")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-steam-id-input"
                                        type="text"
                                        name="steamId"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.steamId}
                                        isInvalid={
                                            !!touched.steamId &&
                                            !!errors.steamId
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.steamId}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="mb-2"
                                    as={Col}
                                    md={6}
                                    controlId="serverForm.url"
                                >
                                    <Form.Label>
                                        {translate("communityForm.url")}
                                        {requiredFieldIcon}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="server-community-url-input"
                                        type="text"
                                        name="communityUrl"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.communityUrl}
                                        isInvalid={
                                            !!touched.communityUrl &&
                                            !!errors.communityUrl
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.communityUrl}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <div className="w-100 d-flex justify-content-end">
                                <Button
                                    variant="secondary"
                                    role="button"
                                    className="me-2"
                                    onClick={cancelHandler}
                                >
                                    {translate("common.cancel")}
                                </Button>
                                <Button
                                    variant="primary"
                                    role="button"
                                    type="submit"
                                    disabled={
                                        serverLoading === httpStatus.pending ||
                                        !isValid
                                    }
                                >
                                    {translate("common.save")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );

    return <div>{server && getForm()}</div>;
};

export default ServerForm;
