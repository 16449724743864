import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Col, Row, Card } from "react-bootstrap";
import KeyValue from "../../shared/components/KeyValue";
import { HardwareData } from "./server.slice";

const HardwareProfileCard: React.FC<{ data: HardwareData }> = ({ data }) => {
    const { t: translate } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <h5>{translate("hardwareForm.title")}</h5>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.operatingSystem")}
                            value={data.operatingSystem}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.cpuModel")}
                            value={data.cpuModel}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.cpuFrequency")}
                            value={data.cpuFrequency}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.physicalMemory")}
                            value={data.physicalMemory}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.diskDrives")}
                            value={data.diskDrives}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.networkSpeed")}
                            value={data.networkSpeed}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("hardwareForm.location")}
                            value={data.location}
                        />
                    </Col>
                    <Col>
                        <KeyValue
                            keyName={translate("hardwareForm.hostingCompany")}
                            value={data?.hostingCompany}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default HardwareProfileCard;
