import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Alert, Spinner } from "react-bootstrap";
import { apply, setUnsavedChangesWarning } from "./licenseApplication.slice";
import { AppDispatch } from "../../shared/store";
import { chevronLeftIcon } from "../../shared/constants/unicodeIcons";
import { FormStep } from "../../pages/ApplicationPage";
import {
    selectAdditionalContacts,
    selectAvailablility,
    selectCommunity,
    selectError,
    selectGeneral,
    selectHardware,
    selectLoading,
    selectPrimaryContact
} from "./licenseApplication.selectors";
import { httpStatus } from "../../shared/api/api";
import GeneralInfoCard from "../servers/GeneralInfoCard";
import HardwareProfileCard from "../servers/HardwareProfileCard";
import CommunityCard from "../servers/CommunityCard";
import AdminAvailabilityCard from "../servers/AdminAvailabilityCard";
import ContactCard from "../servers/ContactCard";

const ReviewApplication: React.FC<FormStep> = ({
    completedHandler,
    backStepHandler
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const general = useSelector(selectGeneral);
    const hardware = useSelector(selectHardware);
    const community = useSelector(selectCommunity);
    const availability = useSelector(selectAvailablility);
    const primaryContact = useSelector(selectPrimaryContact);
    const additionalContacts = useSelector(selectAdditionalContacts);

    const error = useSelector(selectError);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setShowError(!!error);
    }, [error]);

    const getError = () =>
        showError && (
            <Alert
                variant="danger"
                onClose={() => setShowError(false)}
                dismissible
            >
                <Alert.Heading>
                    {translate("reviewApplication.error")}
                </Alert.Heading>
                {translate("common.errorMessage", {
                    status: error?.status,
                    code: error?.code
                })}
            </Alert>
        );

    const submitHandler = async () => {
        const resultAction = await dispatch(apply());
        if (apply.fulfilled.match(resultAction)) {
            completedHandler?.(true);
            dispatch(setUnsavedChangesWarning(false));
        }
    };

    const getGeneral = () => {
        return general && <GeneralInfoCard data={general} />;
    };

    const getHardware = () => {
        return (
            general?.hardwareProfileRequired &&
            hardware && <HardwareProfileCard data={hardware} />
        );
    };

    const getCommunity = () => {
        return community && <CommunityCard data={community} />;
    };

    const getAvailability = () => {
        return (
            availability && (
                <div data-testid="availability">
                    <AdminAvailabilityCard data={availability} />
                </div>
            )
        );
    };

    const getPrimaryContact = () =>
        primaryContact && <ContactCard data={primaryContact} />;

    const getAdditionalContacts = () =>
        additionalContacts &&
        additionalContacts.length > 0 &&
        additionalContacts.map((item) => (
            <ContactCard key={item.email} data={item} />
        ));

    const getSubmitButton = () => {
        let variant = "primary";
        let label = translate("reviewApplication.apply");
        if (loading === httpStatus.pending) {
            variant = "warning";
            label = translate("reviewApplication.submitting");
        }
        if (loading === httpStatus.fulfilled) {
            variant = "success";
            label = translate("reviewApplication.completed");
        }

        return (
            <Button
                variant={variant}
                className="ms-auto"
                onClick={submitHandler}
                disabled={
                    loading === httpStatus.pending ||
                    loading === httpStatus.fulfilled
                }
            >
                {loading === httpStatus.pending && (
                    <>
                        <Spinner
                            animation="border"
                            variant="dark"
                            role="status"
                            size="sm"
                        />{" "}
                    </>
                )}
                {label}
            </Button>
        );
    };

    return (
        <>
            <h3>{translate("reviewApplication.title")}</h3>
            <p>{translate("reviewApplication.info")}</p>
            <div className="card-list-margin-y">
                {getGeneral()}
                {getHardware()}
                {getCommunity()}
                {getAvailability()}
                {getPrimaryContact()}
                {getAdditionalContacts()}
            </div>
            {error && getError()}
            {loading === httpStatus.fulfilled && (
                <Alert variant="success">
                    <Alert.Heading>
                        {translate("reviewApplication.successTitle")}
                    </Alert.Heading>
                    {translate("reviewApplication.successMessage")}
                </Alert>
            )}
            <div className="d-flex">
                <Button
                    variant="secondary"
                    role="button"
                    type="button"
                    onClick={backStepHandler}
                >
                    {chevronLeftIcon} {translate("common.back")}
                </Button>
                {getSubmitButton()}
            </div>
        </>
    );
};

export default ReviewApplication;
