import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { setUnsavedChangesWarning } from "../../features/licenseApplication/licenseApplication.slice";

const BeforeUnloadFormWarning: React.FC = () => {
    const { dirty } = useFormikContext();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(setUnsavedChangesWarning(dirty));
    }, [dispatch, dirty]);

    return null;
};

export default BeforeUnloadFormWarning;
