import { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppDispatch } from "../../shared/store";
import { selectLoading, selectSingle } from "./serverProvider.selectors";
import {
    getProvider,
    newProviderId,
    ServerProviderData,
    updateProvider
} from "./serverProvider.slice";
import { httpStatus } from "../../shared/api/api";
import { maxLengthMedium } from "../../shared/constants/validation";

const ServerProviderForm: React.FC = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const data = useSelector(selectSingle);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(getProvider(id));
        }
    }, [dispatch, id]);

    const initialValues: ServerProviderData = {
        name: data?.name || "",
        id: data?.id || "",
        hardwareProfileRequired: data?.hardwareProfileRequired || false,
        enabled: data?.enabled || false
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(translate("common.nameRequired"))
    });

    const submitHandler = async (values: ServerProviderData) => {
        const resultAction = await dispatch(updateProvider(values));
        if (updateProvider.fulfilled.match(resultAction)) {
            navigate("/admin/providers");
        }
    };

    const cancelHandler = () => navigate("/admin/providers");

    const getForm = () => (
        <Card>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={submitHandler}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        isValid,
                        errors
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group
                                className="mb-2"
                                controlId="providerForm.name"
                            >
                                <Form.Label>
                                    {translate("common.name")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="name-input"
                                    type="text"
                                    name="name"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Check
                                    type="switch"
                                    role="switch"
                                    id={`enable-checkbox-${values?.id}`}
                                    label={
                                        values?.enabled
                                            ? translate("common.enabled")
                                            : translate("common.disabled")
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "enabled",
                                            e.target.checked
                                        )
                                    }
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.enabled && !!errors.enabled
                                    }
                                    checked={values.enabled}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Check
                                    type="switch"
                                    role="switch"
                                    id={`hardware-checkbox-${values?.id}`}
                                    label={`${translate(
                                        "serverProviders.hardwareProfile"
                                    )} ${
                                        values?.hardwareProfileRequired
                                            ? translate(
                                                  "serverProviders.required"
                                              )
                                            : translate(
                                                  "serverProviders.notRequired"
                                              )
                                    }`}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "hardwareProfileRequired",
                                            e.target.checked
                                        )
                                    }
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.hardwareProfileRequired &&
                                        !!errors.hardwareProfileRequired
                                    }
                                    checked={values.hardwareProfileRequired}
                                />
                            </Form.Group>
                            {values.id !== newProviderId && (
                                <Form.Group
                                    className="mb-2"
                                    controlId="providerForm.id"
                                >
                                    <Form.Label>
                                        {translate("common.id")}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="id-input"
                                        type="text"
                                        name="id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.id}
                                        isInvalid={!!touched.id && !!errors.id}
                                        disabled
                                    />
                                </Form.Group>
                            )}
                            <div className="w-100 d-flex justify-content-end">
                                <Button
                                    variant="secondary"
                                    role="button"
                                    className="me-2"
                                    onClick={cancelHandler}
                                >
                                    {translate("common.cancel")}
                                </Button>
                                <Button
                                    variant="primary"
                                    role="button"
                                    type="submit"
                                    disabled={
                                        loading === httpStatus.pending ||
                                        !isValid
                                    }
                                >
                                    {translate("common.save")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );

    return <div>{data && getForm()}</div>;
};

export default ServerProviderForm;
