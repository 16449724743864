import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../login/login.selectors";
import MainNavigation from "../mainNavigation/MainNavigation";
import Toolbar from "../toolbar/Toolbar";
import style from "./Layout.module.scss";

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    return (
        <>
            {isLoggedIn && <Toolbar />}
            <div
                className={`bg-light ${
                    isLoggedIn ? style["layout-height"] : ""
                }`}
            >
                <div className="d-flex h-100">
                    {isLoggedIn && <MainNavigation />}
                    <main
                        className={`pt-3 w-100 ${style["overflow-y-scroll"]}`}
                    >
                        <div className="container-fluid d-flex justify-content-center">
                            {children}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Layout;
