import { RootState } from "../../shared/store";
import AppError from "../../shared/errors/appError";
import { ApiVersionData } from "./apiVersion.slice";

export const selectLoading: (state: RootState) => string = (store) =>
    store.apiVersion.loading;

export const selectData: (state: RootState) => ApiVersionData | null = (
    store
) => store.apiVersion.data;

export const selectError: (state: RootState) => AppError | null = (store) =>
    store.apiVersion.error;
