import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Col, Row, Card } from "react-bootstrap";
import KeyValue from "../../shared/components/KeyValue";
import { LicenseCardData } from "./server.slice";

const LicenseCard: React.FC<{
    data: LicenseCardData;
}> = ({ data }) => {
    const { t: translate } = useTranslation();

    const getDateTime = () => {
        if (data?.lastModifiedTimestamp) {
            return DateTime.fromMillis(
                data.lastModifiedTimestamp
            ).toLocaleString(DateTime.DATE_MED);
        }
        return "";
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title>{translate("License")}</Card.Title>
                <Row>
                    <Col md>
                        <KeyValue
                            keyName={translate("searchForm.licenseNumber")}
                            value={data.licenseNumber}
                        />
                    </Col>
                    <Col md>
                        <KeyValue
                            keyName={translate("licenses.lastModified")}
                            value={getDateTime()}
                        />
                    </Col>
                    <Col md={6}>
                        <KeyValue
                            keyName={translate(
                                "licenseStatusChange.statusChangeReason"
                            )}
                            value={data.statusReason}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default LicenseCard;
