import { useEffect, useRef, useState } from "react";
import { Field, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../shared/store";
import "../../translations/i18n";
import { search, SearchQuery } from "./server.slice";
import { licenseStatus } from "../licenses/license.slice";
import { GameData, getList as getGamesList } from "../games/game.slice";
import { selectList as selectGamesList } from "../games/game.selectors";
import {
    ipAddressPattern,
    numbersPattern,
    steam64IdPattern
} from "../../shared/constants/regex";
import style from "./SearchForm.module.scss";
import testIds from "../../shared/constants/testIds";
import { useAppSelector } from "../../shared/hooks";
import { selectSearchQuery } from "./server.selectors";
import { searchQueryLimit } from "../../shared/constants/searchQuery";

const SearchForm: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const handleClose = () => setShowMobileSearch(false);
    const handleShow = () => setShowMobileSearch(true);

    const games = useAppSelector(selectGamesList);
    const searchQuery = useAppSelector(selectSearchQuery);

    const formikRef = useRef<FormikProps<SearchQuery>>(null);

    useEffect(() => {
        dispatch(getGamesList());
        formikRef?.current?.submitForm();
    }, [dispatch]);

    const schema = Yup.object().shape({
        ipAddress: Yup.string().matches(
            ipAddressPattern,
            translate("generalForm.ipAddressInvalid")
        ),
        licenseNumbers: Yup.string().matches(
            numbersPattern,
            translate("searchForm.licenseNumberInvalid")
        ),
        contactSteamId: Yup.string().matches(
            steam64IdPattern,
            translate("searchForm.contactSteamIdInvalid")
        )
    });

    const defaultValues: SearchQuery = {
        ipAddress: "",
        contactEmail: "",
        contactName: "",
        licenseNumbers: "",
        licenseStatuses: [],
        communityName: "",
        contactSteamId: "",
        gameIds: []
    };

    const initialValues: SearchQuery = {
        ...defaultValues,
        ...searchQuery
    };

    const submitHandler = (values: SearchQuery) => {
        dispatch(search({ ...values, limit: searchQueryLimit, offset: 0 }));
        setShowMobileSearch(false);
    };

    const getForm = () => (
        <Formik
            innerRef={formikRef}
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={submitHandler}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                resetForm,
                isValid
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group
                            as={Col}
                            lg
                            controlId="searchForm.ipAddress"
                        >
                            <Form.Label>
                                {translate("generalForm.ipAddress")}
                            </Form.Label>
                            <Form.Control
                                aria-label="server-ip-address-input"
                                type="text"
                                name="ipAddress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ipAddress}
                                isInvalid={
                                    !!touched.ipAddress && !!errors.ipAddress
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.ipAddress}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            lg
                            controlId="searchForm.contactEmail"
                        >
                            <Form.Label>
                                {translate("searchForm.contactEmail")}
                            </Form.Label>
                            <Form.Control
                                aria-label="contact-email-input"
                                type="text"
                                name="contactEmail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contactEmail}
                                isInvalid={
                                    !!touched.contactEmail &&
                                    !!errors.contactEmail
                                }
                            />
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            lg
                            controlId="searchForm.licenseNumbers"
                        >
                            <Form.Label>
                                {translate("searchForm.licenseNumber")}
                            </Form.Label>
                            <Form.Control
                                aria-label="license-numbers-input"
                                type="text"
                                name="licenseNumbers"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.licenseNumbers}
                                isInvalid={
                                    !!touched.licenseNumbers &&
                                    !!errors.licenseNumbers
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.licenseNumbers}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            lg
                            controlId="searchForm.licenseStatuses"
                        >
                            <Form.Label className="mb-0 text-muted">
                                {translate("searchForm.licenseStatuses")}
                            </Form.Label>
                            <div
                                className={style.grid}
                                role="group"
                                aria-labelledby="status-group"
                            >
                                {Object.values(licenseStatus).map(
                                    (status: string) => (
                                        <div
                                            className="form-check"
                                            key={status}
                                        >
                                            <label
                                                className="form-check-label text-capitalize"
                                                htmlFor={status}
                                            >
                                                <Field
                                                    type="checkbox"
                                                    name="licenseStatuses"
                                                    value={status}
                                                    id={status}
                                                    className="form-check-input"
                                                />
                                                {status}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </Form.Group>
                    </Row>
                    {showMoreOptions && (
                        <Row>
                            <Form.Group
                                as={Col}
                                lg
                                controlId="searchForm.contactName"
                            >
                                <Form.Label>
                                    {translate("searchForm.contactName")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="contact-name-input"
                                    type="text"
                                    name="contactName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contactName}
                                    isInvalid={
                                        !!touched.contactName &&
                                        !!errors.contactName
                                    }
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                lg
                                controlId="searchForm.communityName"
                            >
                                <Form.Label>
                                    {translate("searchForm.communityName")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="community-name-input"
                                    type="text"
                                    name="communityName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.communityName}
                                    isInvalid={
                                        !!touched.communityName &&
                                        !!errors.communityName
                                    }
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                lg
                                controlId="searchForm.contactSteamId"
                            >
                                <Form.Label>
                                    {translate("searchForm.contactSteamId")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="steam-id-input"
                                    type="text"
                                    name="contactSteamId"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contactSteamId}
                                    isInvalid={
                                        !!touched.contactSteamId &&
                                        !!errors.contactSteamId
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.contactSteamId}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                lg
                                controlId="searchForm.gameIds"
                            >
                                <Form.Label className="mb-0">
                                    {translate("searchForm.game")}
                                </Form.Label>
                                <div role="group" aria-labelledby="games-group">
                                    {games &&
                                        games.length > 0 &&
                                        games.map((game: GameData) => (
                                            <div
                                                className="form-check"
                                                key={game.id}
                                            >
                                                <label
                                                    className="form-check-label text-capitalize"
                                                    htmlFor={game.id}
                                                >
                                                    <Field
                                                        type="checkbox"
                                                        name="gameIds"
                                                        value={game.id}
                                                        id={game.id}
                                                        className="form-check-input"
                                                    />
                                                    {game.name}{" "}
                                                    {game.enabled
                                                        ? ""
                                                        : translate(
                                                              "common.parentheses",
                                                              {
                                                                  value: translate(
                                                                      "common.disabled"
                                                                  )
                                                              }
                                                          )}
                                                </label>
                                            </div>
                                        ))}
                                </div>
                            </Form.Group>
                        </Row>
                    )}
                    <div className="d-flex">
                        <div className="d-flex">
                            <Button
                                className="px-5 me-1"
                                role="button"
                                type="submit"
                                data-testid={testIds.searchForm.search}
                                disabled={!isValid}
                            >
                                {translate("searchForm.search")}
                            </Button>
                            <Button
                                className="me-1"
                                role="button"
                                variant="outline-secondary"
                                onClick={() => {
                                    resetForm({
                                        values: defaultValues
                                    });
                                }}
                            >
                                {translate("searchForm.clear")}
                            </Button>
                            <Button
                                className="me-1"
                                role="button"
                                variant="outline-secondary"
                                onClick={() =>
                                    setShowMoreOptions((prev) => !prev)
                                }
                            >
                                {showMoreOptions
                                    ? translate("searchForm.showLess")
                                    : translate("searchForm.showMore")}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );

    const getMobileForm = () => (
        <>
            <Button
                variant="primary"
                className="w-100"
                onClick={handleShow}
                data-testid={testIds.searchForm.showMobileForm}
            >
                {translate("searchForm.search")}
            </Button>
            <Modal
                size="lg"
                show={showMobileSearch}
                onHide={handleClose}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{translate("searchForm.search")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{getForm()}</Modal.Body>
            </Modal>
        </>
    );

    return (
        <div>
            <div className="d-lg-none">{getMobileForm()}</div>
            {/*
                Must not show two instances of the same form or
                dup ids causes label focus to fail
            */}
            {!showMobileSearch && (
                <div className="d-lg-block d-none">{getForm()}</div>
            )}
        </div>
    );
};

export default SearchForm;
