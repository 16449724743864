import { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Formik } from "formik";
import * as Yup from "yup";
import { selectSingle, selectLoading } from "./game.selectors";
import { GameData, getGame, updateGame } from "./game.slice";
import { httpStatus } from "../../shared/api/api";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import {
    maxLengthLarge,
    maxLengthMedium
} from "../../shared/constants/validation";

const GameForm: React.FC = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const data = useAppSelector(selectSingle);

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(getGame(id));
        }
    }, [dispatch, id]);

    const initialValues: GameData = {
        name: data?.name || "",
        licenseAgreementUrl: data?.licenseAgreementUrl || "",
        enabled: data?.enabled || false,
        id: data?.id || ""
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(translate("common.nameRequired")),
        licenseAgreementUrl: Yup.string()
            .required(translate("games.licenseAgreementUrlRequired"))
            .url(translate("games.licenseAgreementUrlInvalid"))
    });

    const submitHandler = async (values: GameData) => {
        const resultAction = await dispatch(updateGame(values));
        if (updateGame.fulfilled.match(resultAction)) {
            navigate("/admin/games");
        }
    };

    const cancelHandler = () => navigate("/admin/games");

    const getForm = () => (
        <Card>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={submitHandler}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        isValid,
                        errors
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group
                                className="mb-2"
                                controlId="gameForm.name"
                            >
                                <Form.Label>
                                    {translate("common.name")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="name-input"
                                    type="text"
                                    name="name"
                                    maxLength={maxLengthMedium}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Check
                                    type="switch"
                                    role="switch"
                                    id={`enable-checkbox-${values?.id}`}
                                    label={
                                        values?.enabled
                                            ? translate("common.enabled")
                                            : translate("common.disabled")
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "enabled",
                                            e.target.checked
                                        )
                                    }
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.enabled && !!errors.enabled
                                    }
                                    checked={values.enabled}
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-2"
                                controlId="gameForm.url"
                            >
                                <Form.Label>
                                    {translate("games.licenseAgreementUrl")}
                                </Form.Label>
                                <Form.Control
                                    aria-label="agreement-url-input"
                                    type="text"
                                    name="licenseAgreementUrl"
                                    maxLength={maxLengthLarge}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.licenseAgreementUrl}
                                    isInvalid={
                                        !!touched.licenseAgreementUrl &&
                                        !!errors.licenseAgreementUrl
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.licenseAgreementUrl}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {values.id && (
                                <Form.Group
                                    className="mb-2"
                                    controlId="gameForm.id"
                                >
                                    <Form.Label>
                                        {translate("common.id")}
                                    </Form.Label>
                                    <Form.Control
                                        aria-label="id-input"
                                        type="text"
                                        name="id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.id}
                                        isInvalid={!!touched.id && !!errors.id}
                                        disabled
                                    />
                                </Form.Group>
                            )}
                            <div className="w-100 d-flex justify-content-end">
                                <Button
                                    variant="secondary"
                                    role="button"
                                    className="me-2"
                                    onClick={cancelHandler}
                                >
                                    {translate("common.cancel")}
                                </Button>
                                <Button
                                    variant="primary"
                                    role="button"
                                    type="submit"
                                    disabled={
                                        loading === httpStatus.pending ||
                                        !isValid
                                    }
                                >
                                    {translate("common.save")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );

    return <div>{data && getForm()}</div>;
};

export default GameForm;
