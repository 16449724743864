import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Form, ListGroup, ListGroupItem, Table } from "react-bootstrap";
import {
    getList,
    ServerProviderData,
    updateProvider
} from "./serverProvider.slice";
import { selectList, selectLoading } from "./serverProvider.selectors";
import { AppDispatch } from "../../shared/store";
import { httpStatus } from "../../shared/api/api";
import { checkIcon, crossIcon } from "../../shared/constants/unicodeIcons";

const ServerProvidersList: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const list = useSelector(selectList);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    const enabledHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        provider: ServerProviderData
    ) => {
        const update: ServerProviderData = {
            ...provider,
            enabled: event.target.checked
        };
        dispatch(updateProvider(update));
    };

    const getDesktop = () => (
        <Table
            bordered
            hover
            className="bg-white"
            data-testid="desktop-server-providers"
        >
            {list && list.length > 0 && (
                <thead>
                    <tr>
                        <th>{translate("common.name")}</th>
                        <th>{translate("serverProviders.hardwareProfile")}</th>
                        <th>{translate("common.enabled")}</th>
                    </tr>
                </thead>
            )}
            <tbody>
                {list &&
                    list.length > 0 &&
                    list.map((provider) => (
                        <tr key={provider.id}>
                            <td>
                                <Link to={`/admin/providers/${provider.id}`}>
                                    {provider.name}
                                </Link>
                            </td>
                            <td>
                                <span>
                                    {provider.hardwareProfileRequired
                                        ? `${checkIcon} ${translate(
                                              "serverProviders.required"
                                          )}`
                                        : `${crossIcon} ${translate(
                                              "serverProviders.notRequired"
                                          )}`}
                                </span>
                            </td>
                            <td>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id={`desktop-provider-enabled-checkbox-${provider.id}`}
                                        data-testid={`desktop-provider-enabled-checkbox-${provider.id}`}
                                        label={
                                            provider.enabled
                                                ? translate("common.enabled")
                                                : translate("common.disabled")
                                        }
                                        checked={provider.enabled}
                                        onChange={(event) =>
                                            enabledHandler(event, provider)
                                        }
                                        disabled={
                                            loading === httpStatus.pending
                                        }
                                    />
                                </Form>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );

    const getMobile = () => (
        <ListGroup data-testid="mobile-server-providers">
            {list &&
                list.length > 0 &&
                list.map((provider) => (
                    <ListGroupItem
                        key={provider.id}
                        className="d-flex justify-content-between"
                    >
                        <Link to={`/admin/providers/${provider.id}`}>
                            {provider.name}
                        </Link>
                        <Form>
                            <Form.Check
                                type="switch"
                                id={`mobile-provider-enabled-checkbox-${provider.id}`}
                                data-testid={`mobile-provider-enabled-checkbox-${provider.id}`}
                                label={
                                    provider.enabled
                                        ? translate("common.enabled")
                                        : translate("common.disabled")
                                }
                                checked={provider.enabled}
                                onChange={(event) =>
                                    enabledHandler(event, provider)
                                }
                                disabled={loading === httpStatus.pending}
                            />
                        </Form>
                    </ListGroupItem>
                ))}
        </ListGroup>
    );

    return (
        <>
            <div className="d-lg-block d-none">{getDesktop()}</div>
            <div className="d-lg-none">{getMobile()}</div>
            {loading === httpStatus.fulfilled && list?.length === 0 && (
                <p className="text-muted">
                    {translate("serverProviders.noneFound")}
                </p>
            )}
        </>
    );
};

export default ServerProvidersList;
