import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { getApiVersion } from "./apiVersion.slice";
import { selectData, selectLoading } from "./apiVersion.selectors";
import {
    selectData as selectClientData,
    selectLoading as selectClientLoading
} from "../buildVersion/buildVersion.selectors";
import { httpStatus } from "../../shared/api/api";
import KeyValue from "../../shared/components/KeyValue";
import BuildVersion from "../buildVersion/BuildVersion";

const ApiVersion: React.FC<{ classNames?: string }> = ({ classNames = "" }) => {
    const { t: translate } = useTranslation();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const data = useAppSelector(selectData);

    const clientLoading = useAppSelector(selectClientLoading);
    const clientData = useAppSelector(selectClientData);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        dispatch(getApiVersion());
    };

    const getModal = () => (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{translate("version.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <KeyValue
                        keyName={translate("version.clientVersion")}
                        value={`
                            ${
                                clientLoading === httpStatus.pending
                                    ? translate("common.loading")
                                    : ""
                            }
                            ${
                                clientLoading === httpStatus.fulfilled
                                    ? clientData
                                    : ""
                            }
                            ${
                                clientLoading === httpStatus.rejected
                                    ? translate("version.errorClientVersion")
                                    : ""
                            }
                        `}
                        valueClassnames={
                            clientLoading === httpStatus.rejected
                                ? "text-danger"
                                : ""
                        }
                    />
                    <KeyValue
                        keyName={translate("version.apiVersion")}
                        value={`
                            ${
                                loading === httpStatus.pending
                                    ? translate("common.loading")
                                    : ""
                            }
                            ${
                                loading === httpStatus.fulfilled
                                    ? data?.buildVersion
                                    : ""
                            }
                            ${
                                loading === httpStatus.rejected
                                    ? translate("version.errorApiVersion")
                                    : ""
                            }
                        `}
                        valueClassnames={
                            loading === httpStatus.rejected ? "text-danger" : ""
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {translate("common.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <>
            <Button
                variant="link"
                size="sm"
                className={classNames}
                onClick={handleShow}
            >
                <BuildVersion />
            </Button>
            {getModal()}
        </>
    );
};

export default ApiVersion;
