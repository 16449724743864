import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, ListGroup, ListGroupItem, Table } from "react-bootstrap";
import { httpStatus } from "../../shared/api/api";
import { selectLoading, selectList } from "./game.selectors";
import { GameData, getList, updateGame } from "./game.slice";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";

const GamesList: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const data = useAppSelector(selectList);

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    const enabledHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        game: GameData
    ) => {
        const update: GameData = {
            ...game,
            enabled: event.target.checked
        };
        dispatch(updateGame(update));
    };

    const getDesktop = () => (
        <Table bordered hover className="bg-white" data-testid="desktop-games">
            {data && data.length > 0 && (
                <thead>
                    <tr>
                        <th>{translate("common.name")}</th>
                        <th>{translate("games.agreementUrl")}</th>
                        <th>{translate("common.enabled")}</th>
                    </tr>
                </thead>
            )}
            <tbody>
                {data &&
                    data.map((game) => (
                        <tr key={game.id}>
                            <td>
                                <Link to={`/admin/games/${game.id}`}>
                                    {game.name}
                                </Link>
                            </td>
                            <td>{game.licenseAgreementUrl}</td>
                            <td>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id={`desktop-enable-checkbox-${game.id}`}
                                        data-testid={`desktop-enable-checkbox-${game.id}`}
                                        label={
                                            game.enabled
                                                ? translate("common.enabled")
                                                : translate("common.disabled")
                                        }
                                        checked={game.enabled}
                                        onChange={(event) =>
                                            enabledHandler(event, game)
                                        }
                                        disabled={
                                            loading === httpStatus.pending
                                        }
                                    />
                                </Form>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );

    const getMobile = () => (
        <ListGroup data-testid="mobile-games">
            {data &&
                data.map((game) => (
                    <ListGroupItem key={game.id}>
                        <div className="d-flex justify-content-between">
                            <Link to={`/admin/games/${game.id}`}>
                                {game.name}
                            </Link>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id={`mobile-enable-checkbox-${game.id}`}
                                    data-testid={`mobile-enable-checkbox-${game.id}`}
                                    label={
                                        game.enabled
                                            ? translate("common.enabled")
                                            : translate("common.disabled")
                                    }
                                    checked={game.enabled}
                                    onChange={(event) =>
                                        enabledHandler(event, game)
                                    }
                                    disabled={loading === httpStatus.pending}
                                />
                            </Form>
                        </div>
                    </ListGroupItem>
                ))}
        </ListGroup>
    );

    return (
        <>
            <div className="d-lg-block d-none">{getDesktop()}</div>
            <div className="d-lg-none">{getMobile()}</div>
            {loading === httpStatus.fulfilled && data?.length === 0 && (
                <p className="text-muted">{translate("games.noneFound")}</p>
            )}
        </>
    );
};

export default GamesList;
