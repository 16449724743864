import { useEffect, useState } from "react";
import {
    BsFileEarmarkMedical,
    BsDashCircle,
    BsHandThumbsDown,
    BsHandThumbsUp,
    BsQuestionCircle,
    BsXCircle
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Formik } from "formik";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { AppDispatch } from "../../shared/store";
import { selectLoading } from "./license.selectors";
import {
    changeStatus,
    licenseAction,
    licenseStatus,
    statusStyle,
    statusTransitions
} from "./license.slice";
import { httpStatus } from "../../shared/api/api";
import { arrowRight } from "../../shared/constants/unicodeIcons";
import { maxLengthLarge } from "../../shared/constants/validation";

export interface StatusChange {
    classNames?: string;
    currentStatus?: string;
    id?: string;
    name?: string;
    callBack?: () => void;
}

const LicenseStatusChange: React.FC<StatusChange> = ({
    classNames = "",
    currentStatus = "",
    id,
    name,
    callBack
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const [current, setCurrent] = useState(currentStatus);
    const [actionList, setActionList] = useState<string[]>([]);
    const [action, setAction] = useState("");
    const [style, setStyle] = useState<string>(statusStyle.unknown);

    const [showConfirm, setShowConfirm] = useState(false);
    const closeConfirmHandler = () => setShowConfirm(false);
    const openConfirmHandler = () => setShowConfirm(true);

    useEffect(() => {
        setActionList(statusTransitions.getActions(current?.toLowerCase()));
        setStyle(statusTransitions.getStyle(current || ""));
    }, [current]);

    useEffect(() => {
        setCurrent(currentStatus);
    }, [currentStatus]);

    const updateStatus = async (reason: string) => {
        if (!id || !action) {
            return;
        }
        const resultAction = await dispatch(
            changeStatus({ id, action, reason })
        );
        if (changeStatus.fulfilled.match(resultAction)) {
            const newStatus = statusTransitions.getStatus(action);
            setCurrent(newStatus);
            if (callBack) {
                callBack();
            }
        }
    };

    const submitHandler = (values: { reason: string }) => {
        closeConfirmHandler();
        updateStatus(values.reason);
    };

    const optionHandler = (option: string) => {
        setAction(option);
        openConfirmHandler();
    };

    const getStatusTranslation = (status: string) => {
        const lowerCase = status.toLowerCase();
        if (Object.keys(licenseStatus).includes(lowerCase)) {
            return translate(`licenseStatusChange.${lowerCase}`);
        }
        return translate("licenseStatusChange.unknown");
    };

    const getActionIcon = (label: string): JSX.Element => {
        switch (label?.toLowerCase()) {
            case licenseStatus.applied: {
                return <BsFileEarmarkMedical />;
            }
            case licenseStatus.active:
            case licenseAction.approve:
            case licenseAction.enable: {
                return <BsHandThumbsUp />;
            }
            case licenseStatus.rejected:
            case licenseAction.reject: {
                return <BsHandThumbsDown />;
            }
            case licenseStatus.revoked:
            case licenseAction.revoke: {
                return <BsXCircle />;
            }
            case licenseStatus.disabled:
            case licenseAction.disable: {
                return <BsDashCircle />;
            }
            default: {
                return <BsQuestionCircle />;
            }
        }
    };

    const getDropdown = () => (
        <Dropdown>
            <Dropdown.Toggle
                variant={style}
                className={`text-capitalize ${classNames}`}
                id="dropdown-status-options"
                disabled={loading === httpStatus.pending}
            >
                <span className="me-1">{getActionIcon(current)}</span>
                {getStatusTranslation(current)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {actionList.length > 0 &&
                    actionList.map((option: string) => (
                        <Dropdown.Item
                            key={option}
                            onClick={() => optionHandler(option)}
                        >
                            <span className="me-1">
                                {getActionIcon(option)}
                            </span>
                            {translate(`licenseStatusChange.${option}`)}
                        </Dropdown.Item>
                    ))}
                {actionList.length === 0 && (
                    <Dropdown.Item disabled>
                        {translate("licenseStatusChange.noActions")}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );

    const getConfirmModal = () => (
        <Modal
            show={showConfirm}
            onHide={closeConfirmHandler}
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {translate("licenseStatusChange.confirmTitle")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-capitalize">
                <Formik initialValues={{ reason: "" }} onSubmit={submitHandler}>
                    {({ handleSubmit, handleChange, handleBlur, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <small className="text-muted">
                                {translate("licenseStatusChange.server")}{" "}
                            </small>
                            <div className="mb-2">{name || ""}</div>
                            <small className="text-muted">
                                {translate("licenseStatusChange.statusChange")}{" "}
                            </small>
                            <div className="mb-2">
                                <span>{currentStatus.toLocaleLowerCase()}</span>{" "}
                                {arrowRight}{" "}
                                <span>
                                    {statusTransitions.getStatus(action)}
                                </span>
                            </div>
                            {!statusTransitions.isReasonMessageHidden(
                                action
                            ) && (
                                <Form.Group
                                    controlId="statusChange.reason"
                                    className="mb-2"
                                >
                                    <small>
                                        <Form.Label className="text-muted">
                                            {translate(
                                                "licenseStatusChange.statusChangeReason"
                                            )}
                                        </Form.Label>
                                    </small>
                                    <Form.Control
                                        aria-label="status-change-reason-input"
                                        as="textarea"
                                        rows={3}
                                        name="reason"
                                        maxLength={maxLengthLarge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.reason}
                                    />
                                </Form.Group>
                            )}
                            <div className="d-flex justify-content-end">
                                <Button
                                    type="button"
                                    variant="outline-secondary"
                                    className="me-1"
                                    onClick={closeConfirmHandler}
                                >
                                    {translate("common.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    variant={statusTransitions.getStyle(
                                        statusTransitions.getStatus(action)
                                    )}
                                >
                                    {translate(
                                        "licenseStatusChange.changeStatusTo"
                                    )}{" "}
                                    {getStatusTranslation(
                                        statusTransitions.getStatus(action)
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );

    return (
        <>
            {getDropdown()}
            {getConfirmModal()}
        </>
    );
};

export default LicenseStatusChange;
