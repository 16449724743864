import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { AppDispatch } from "../../shared/store";
import { logoutUser, resetStore } from "../login/login.slice";
import navLinks from "../../shared/constants/navLinks";
import { menuIcon } from "../../shared/constants/unicodeIcons";
import LoginPrompt from "../login/LoginPrompt";
import ApiVersion from "../apiVersion/ApiVersion";
import { selectLoading as selectClientLoading } from "../buildVersion/buildVersion.selectors";
import { useAppSelector } from "../../shared/hooks";
import { httpStatus } from "../../shared/api/api";

const Toolbar: React.FC = () => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const versionLoading = useAppSelector(selectClientLoading);

    const placeholderUser = "Admin";

    const logoutHandler = async () => {
        dispatch(logoutUser());
        dispatch(resetStore());
        navigate("/login");
    };

    return (
        <Navbar
            collapseOnSelect
            expand="md"
            bg="dark"
            variant="dark"
            role="banner"
        >
            <Container fluid>
                <Dropdown className="d-lg-none me-2" role="menu">
                    <Dropdown.Toggle
                        variant="dark"
                        id="dropdown-mobile-nav"
                        bsPrefix="no-arrow"
                    >
                        {menuIcon}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {navLinks
                            .filter((link) => link.protected)
                            .map((link) => (
                                <Dropdown.Item
                                    key={link.label}
                                    as={RouterLink}
                                    to={link.route}
                                >
                                    {translate(`navLinks.${link.label}`)}
                                </Dropdown.Item>
                            ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => logoutHandler()}>
                            {translate("common.logout")}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.ItemText className="text-muted">
                            <small className="d-flex align-items-center flex-wrap">
                                <span className="pe-1">
                                    {translate("version.version")}
                                </span>
                                <ApiVersion
                                    classNames={`p-0 ${
                                        versionLoading === httpStatus.rejected
                                            ? "text-danger"
                                            : "text-primary"
                                    }`}
                                />
                            </small>
                        </Dropdown.ItemText>
                    </Dropdown.Menu>
                </Dropdown>
                <span className="navbar-brand text-light">
                    {translate("common.appTitle")}
                </span>
                <div className="d-flex ms-auto">
                    <Dropdown
                        className="d-lg-block d-none"
                        role="menu"
                        align="end"
                    >
                        <Dropdown.Toggle
                            variant="dark"
                            id="dropdown-user-options-menu"
                        >
                            {placeholderUser}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => logoutHandler()}>
                                {translate("common.logout")}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.ItemText className="text-muted">
                                <small className="d-flex align-items-center flex-wrap">
                                    <span className="pe-1">
                                        {translate("version.version")}
                                    </span>
                                    <ApiVersion
                                        classNames={`p-0 ${
                                            versionLoading ===
                                            httpStatus.rejected
                                                ? "text-danger"
                                                : "text-primary"
                                        }`}
                                    />
                                </small>
                            </Dropdown.ItemText>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <LoginPrompt />
            </Container>
        </Navbar>
    );
};

export default Toolbar;
