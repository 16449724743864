export const arrowRight = "🠖";
export const checkIcon = "✔";
export const chevronLeftIcon = "<";
export const chevronRightIcon = ">";
export const copyrightIcon = "©";
export const crossIcon = "❌";
export const crossSmallIcon = "×";
export const menuIcon = "☰";
export const minusIcon = "-";
export const plusIcon = "+";
export const requiredFieldIcon = "*";
