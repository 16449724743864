import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppError from "../errors/appError";
import { AppDispatch } from "../store";

export interface Error {
    heading?: string;
    isShown?: boolean;
    details?: AppError | null;
}

const ErrorAlert: React.FC<Error> = ({ heading, isShown, details }) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [errorDetails, setErrorDetails] = useState(details);

    useEffect(() => {
        setShowError(!!isShown);
    }, [isShown]);

    useEffect(() => {
        setErrorDetails(details);
    }, [dispatch, details]);

    return (
        <div>
            {showError && (
                <Alert
                    variant="danger"
                    onClose={() => setShowError(false)}
                    dismissible
                >
                    <Alert.Heading>
                        {heading || translate("common.error")}
                    </Alert.Heading>
                    <div className="d-flex flex-column">
                        {errorDetails?.status && (
                            <span>
                                {translate("errorAlert.status", {
                                    status: errorDetails.status
                                })}
                            </span>
                        )}
                        {errorDetails?.code && (
                            <span>
                                {translate("errorAlert.code", {
                                    code: errorDetails.code
                                })}
                            </span>
                        )}
                    </div>
                </Alert>
            )}
        </div>
    );
};

export default ErrorAlert;
