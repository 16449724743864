import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";
import { TbPencil } from "react-icons/tb";
import Server from "../features/servers/Server";
import {
    selectSingle,
    selectLoading as selectServerLoading,
    selectError as selectServerError
} from "../features/servers/server.selectors";
import {
    selectLoading as selectLicenseLoading,
    selectError as selectLicenseError
} from "../features/licenses/license.selectors";
import { httpStatus } from "../shared/api/api";
import Title from "../shared/components/Title";
import LoadingSpinner from "../shared/components/LoadingSpinner";
import ErrorAlert from "../shared/components/ErrorAlert";
import ServerReport from "../features/serverReport/ServerReport";
import RegenerateKeys from "../features/licenses/RegenerateKeys";
import LicenseStateChange from "../features/licenses/LicenseStatusChange";
import { selectData } from "../features/serverReport/serverReport.selectors";
import { validKeyRegenerationStatuses } from "../features/licenses/license.slice";
import { AppDispatch } from "../shared/store";
import { getServer } from "../features/servers/server.slice";
import ServerForm from "../features/servers/ServerForm";

export const tabs = {
    detials: "details",
    edit: "edit"
} as const;

const ServerDetailPage: React.FC<{ currentTab?: string }> = ({
    currentTab = tabs.detials
}) => {
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const [tabKey, setTabKey] = useState(currentTab || tabs.detials);

    const data = useSelector(selectSingle);
    const serverLoading = useSelector(selectServerLoading);
    const serverError = useSelector(selectServerError);
    const licenseLoading = useSelector(selectLicenseLoading);
    const licenseError = useSelector(selectLicenseError);
    const serverReport = useSelector(selectData);

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(getServer(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        setTabKey(currentTab || tabs.detials);
    }, [currentTab]);

    const tabChangeHandler = (nextTab: string | null) => {
        if (nextTab === currentTab) {
            return;
        }
        navigate(
            `/admin/licenses/${id}${nextTab === tabs.edit ? "/edit" : ""}`
        );
        setTabKey(nextTab || tabs.detials);
    };

    const getTitle = () => {
        let title = "";
        if (data?.name) {
            title = data.name;
        }

        if (
            serverLoading === httpStatus.pending ||
            licenseLoading === httpStatus.pending
        ) {
            title = "";
        }
        return (
            <div className="d-flex align-items-center">
                <Title
                    title={translate("licenses.titleWithName", {
                        name: title
                    })}
                    classes="me-2 text-break"
                />
                {(serverLoading === httpStatus.pending ||
                    licenseLoading === httpStatus.pending) && (
                    <LoadingSpinner />
                )}
            </div>
        );
    };

    const getRegenKeys = () => {
        const includes = validKeyRegenerationStatuses.includes(
            data?.license.status.toLowerCase() || ""
        );
        return includes ? <RegenerateKeys id={data?.license.id} /> : <div />;
    };

    const getServerActions = () => {
        return (
            <>
                <small className="mb-1">
                    {translate("serverReport.serverReport")}{" "}
                    {serverReport?.timestamp &&
                        translate("common.parentheses", {
                            value: DateTime.fromMillis(
                                serverReport.timestamp
                            ).toRelative()
                        })}
                </small>
                {data && (
                    <div className="mb-2">
                        <Row>
                            <Col md={3} className="mb-1 d-grid">
                                <ServerReport id={data.license.id} />
                            </Col>
                            <Col md={3} className="mb-1 d-grid" />
                            <Col md={3} className="mb-1 d-grid">
                                {getRegenKeys()}
                            </Col>
                            <Col md={3} className="mb-1 d-grid">
                                <LicenseStateChange
                                    classNames="w-100"
                                    currentStatus={data.license.status}
                                    id={data.license.id}
                                    name={data.name}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </>
        );
    };

    return (
        <Container>
            <div className="d-flex flex-column w-100">
                {getTitle()}
                <ErrorAlert
                    heading={translate("licenses.error")}
                    isShown={!!serverError || !!licenseError}
                    details={serverError || licenseError}
                />
                <Tabs
                    id="server-tabs"
                    activeKey={tabKey}
                    onSelect={tabChangeHandler}
                    className="mb-3 server-tabs"
                >
                    <Tab
                        eventKey={tabs.detials}
                        title={
                            <span>
                                <BiDetail className="me-1" />
                                {translate("servers.details")}
                            </span>
                        }
                    >
                        {data && serverLoading !== httpStatus.pending && (
                            <>
                                {getServerActions()}
                                <Server />
                            </>
                        )}
                    </Tab>
                    <Tab
                        eventKey={tabs.edit}
                        title={
                            <span>
                                <TbPencil className="me-1" />
                                {translate("servers.edit")}
                            </span>
                        }
                    >
                        <ServerForm />
                    </Tab>
                </Tabs>
            </div>
        </Container>
    );
};

export default ServerDetailPage;
